import { useAppContext } from 'components/Context/AppContext';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Day = ({ day }) => {
  const { setShowTaskModal, setSelectedTask, setDaySelected } = useAppContext();
  const [taskList, setTaskList] = useState([]);
  const { data } = useSelector((state) => state.driverTask);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    const tasks = data.data.filter((task) => dayjs(task.start_date).format('DD-MM-YY') === day.format('DD-MM-YY'));
    setTaskList(tasks);
  }, [data, day]);

  function getCurrentDayClass() {
    return day.format('DD-MM-YY') === dayjs().format('DD-MM-YY') ? 'driver-task__day-item--active' : '';
  }

  return (
    <>
      <div className="driver-task__day-wrapper">
        <header className="driver-task__day-header">
          <p className={`driver-task__day-item  ${getCurrentDayClass()}`}>{day.format('DD')}</p>
        </header>

        {taskList?.length > 2
          ? taskList.slice(0, 2).map((task, idx) => (
              <React.Fragment key={idx}>
                <div
                  className="driver-task__task-item"
                  onClick={() => {
                    setShowTaskModal(true);
                    setDaySelected(day);
                    setSelectedTask([task]);
                  }}
                >
                  {task.driver_name}
                </div>

                {idx === 1 && (
                  <button
                    className="driver-task__show-more-btn"
                    onClick={() => {
                      setShowTaskModal(true);
                      setDaySelected(day);
                      setSelectedTask(taskList.slice(2, taskList.length));
                    }}
                  >
                    +{taskList?.length - 2} more
                  </button>
                )}
              </React.Fragment>
            ))
          : taskList.map((task, idx) => (
              <div
                key={idx}
                className="driver-task__task-item"
                onClick={() => {
                  setShowTaskModal(true);
                  setDaySelected(day);
                  setSelectedTask([task]);
                }}
              >
                {task.driver_name}
              </div>
            ))}
      </div>
    </>
  );
};

export default Day;
