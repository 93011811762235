import { createSlice } from '@reduxjs/toolkit';
import { getRefferalReportGraphic } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const refferalReportGraphicSlice = createSlice({
  name: 'refferalReportGraphicSlice',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRefferalReportGraphic.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = {};
      })
      .addCase(getRefferalReportGraphic.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getRefferalReportGraphic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export default refferalReportGraphicSlice.reducer;
