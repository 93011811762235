import { useAppContext } from 'components/Context/AppContext';
import React, { useEffect, useState } from 'react';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as GarageFilledIcon } from 'icons/garage-filled-icon.svg';
import { addGarages, updateGarages } from 'features/garages/actions';
import { ReactComponent as MagnifierIcon } from 'icons/magnifier.svg';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import { resetGarageDetail } from 'features/garages/slice';
import { checkPermission } from 'utils/functionality';

const NewGarageModal = () => {
  const dispatch = useDispatch();

  const { setShowAddNewGarage, showToast } = useAppContext();
  const { data } = useSelector((state) => state.rentalLocation);
  const { garageDetail } = useSelector((state) => state.garages);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [garageName, setGarageName] = useState('');
  const [garageAddress, setGarageAddress] = useState('');
  const [garageMapsLink, setGarageMapsLink] = useState('');
  const [jamBuka, setJamBuka] = useState('');
  const [jamTutup, setJamTutup] = useState('');
  const [cityId, setCityId] = useState(0);
  const [selectedService, setSelectedService] = useState([]);
  const [outsideOpsFee, setOutsideOpsFee] = useState('');

  // fill every field with garage detail data if detail garage
  useEffect(() => {
    if (Object.keys(garageDetail).length === 0) return;

    setGarageName(garageDetail.name);
    setJamBuka(garageDetail.start_time);
    setJamTutup(garageDetail.end_time);
    setCityId(garageDetail.location_id);
    setGarageMapsLink(garageDetail.map_link);
    setGarageAddress(garageDetail.address_details);
    setSelectedService(garageDetail.outside_operational_service ?? []);
    setOutsideOpsFee(garageDetail.outside_operational_fee);
    setisActive(garageDetail.outside_operational_status ?? false);
  }, [garageDetail]);

  // function sendPayload action
  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, errorMessage2) => {
    try {
      const res = await dispatch(
        action === 'ADD'
          ? addGarages(payload)
          : action === 'UPDATE'
          ? updateGarages({ id: garageDetail.id, payload })
          : () => {},
      );
      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: fulfilledMessage });
        setShowAddNewGarage(false);
        return;
      }
      showToast({ type: 'error', message: errorMessage1 });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage2}: ${error}` });
    }
  };

  const handleTipeSewa = (id, status) => {
    const existedType = selectedService.find((item) => item === SERVICE_DATA.find((el) => el.id === id).value);

    if (existedType && status) return;

    if (status) {
      setSelectedService((prevState) => [...prevState, SERVICE_DATA.find((el) => el.id === id).value]);
    } else {
      const filteredData = selectedService.filter((item) => item !== SERVICE_DATA.find((el) => el.id === id).value);
      setSelectedService(filteredData);
    }
  };

  const handleValueInput = (e) => {
    // const normalizedValue = event.target.value.replace(',', '.');

    // if (e.key === ',' || e.key === '.') {
    //   e.preventDefault();
    // }

    // if (/^\d*$/.test(normalizedValue)) {
    //   // setOutsideOpsFee(isNaN(parseInt(e.target.value)) ? '' : +normalizedValue);
    //   setOutsideOpsFee(isNaN(e.target.value) ? '' : +normalizedValue);
    // }

    // if (/^\d*$/.test(normalizedValue)) {
    // setOutsideOpsFee(event.target.value);
    setOutsideOpsFee(e.target.value);
    // }
  };

  const handleKeyDown = (event) => {
    // Prevent the input of commas, dots, and other non-numeric characters
    if (event.key === ',' || event.key === '.' || event.key === 'e' || event.key === '-') {
      event.preventDefault();
    }
  };

  const handleAddGarage = async () => {
    const payload = {
      name: garageName,
      location_id: parseInt(cityId),
      start_time: jamBuka,
      end_time: jamTutup,
      address_details: garageAddress,
      map_link: garageMapsLink,
      outside_operational_fee: +outsideOpsFee,
      outside_operational_status: isActive,
      outside_operational_service: selectedService,
    };

    // validation
    const validName = garageName.replace(/\s/).length !== 0;
    const validLocation = cityId !== 0;
    const validStartTime = jamBuka.replace(/\s/).length !== 0;
    const validationEndTime = jamTutup.replace(/\s/).length !== 0;
    const validAddressDetails = garageAddress.replace(/\s/).length !== 0;
    const validMapLink = garageMapsLink.replace(/\s/).length !== 0;
    const validService = selectedService.length !== 0;
    const validFee = outsideOpsFee !== '';

    // check validation
    if (!validName) {
      return showToast({ type: 'error', message: 'Masukan Nama Garasi' });
    } else if (!validStartTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Buka' });
    } else if (!validationEndTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Tutup' });
    } else if (!validLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
    } else if (!validMapLink) {
      return showToast({ type: 'error', message: 'Masukan Link Gmaps' });
    } else if (!validAddressDetails) {
      return showToast({ type: 'error', message: 'Masukan Alamat Garasi' });
    } else if (!validService) {
      return showToast({ type: 'error', message: 'Pilih Tipe Sewa' });
    } else if (!validFee) {
      return showToast({ type: 'error', message: 'Masukkan Tarif Penalti' });
    }

    // if add garage
    if (Object.keys(garageDetail).length === 0) {
      sendPayloadData('ADD', payload, 'Berhasil Tambah Garasi', 'Gagal Tambah Garasi', 'Terjadi Kesalahan');
      return;
    }

    // if update garage
    sendPayloadData('UPDATE', payload, 'Berhasil Update Garasi', 'Gagal Update Garasi', 'Terjadi Kesalahan');
    dispatch(resetGarageDetail());
  };

  const [isActive, setisActive] = useState(false);
  const handlePenaltiStatus = () => {
    setisActive((prev) => !prev);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Modals
      setState={setShowAddNewGarage}
      title={Object.keys(garageDetail).length === 0 ? 'Tambah Garasi' : 'Detail Garasi'}
      icon={<GarageFilledIcon />}
    >
      <div className="garage-detail">
        <InputField
          className={'garage-input'}
          label={'Nama Garasi'}
          placeholder={'Masukkan Nama Garasi'}
          value={garageName}
          onChange={(e) => setGarageName(e.target.value)}
          id="nama-garasi"
          name={'username'}
        />

        <div className="garage-detail__time">
          <SelectFieldTime
            label="Jam Buka"
            htmlFor="jam-buka"
            placeholder="00:00"
            value={jamBuka}
            onChange={(hour, minute) => {
              setJamBuka(`${hour ? hour : '00'}:${minute ? minute : '00'}`);
            }}
            icon={false}
            showAllHours={true}
          />
          <SelectFieldTime
            label="Jam Tutup"
            htmlFor="jam-tutup"
            placeholder="00:00"
            value={jamTutup}
            onChange={(hour, minute) => {
              setJamTutup(`${hour ? hour : '00'}:${minute ? minute : '00'}`);
            }}
            disable={jamBuka === '' ? true : false}
            icon={false}
            showAllHours={true}
          />
        </div>

        <SelectFieldDropdown
          label="Lokasi Rental"
          type="select"
          htmlFor="garage-city"
          data={data}
          value={cityId}
          placeholder={'Pilih Lokasi'}
          onChange={(e) => setCityId(e.target.id)}
        />

        <InputField
          className={'garage-input'}
          label={'Link Gmaps'}
          placeholder={'Masukkan Link'}
          value={garageMapsLink}
          onChange={(e) => setGarageMapsLink(e.target.value)}
          id="garasi-map-link"
          name={'map_link'}
        />

        <InputField
          className={'garage-input'}
          label={'Alamat Garasi'}
          placeholder={'Masukkan Alamat Garasi'}
          value={garageAddress}
          onChange={(e) => setGarageAddress(e.target.value)}
          icon={<MagnifierIcon />}
        />

        <InputField
          label="Tarif Penalti"
          type="number"
          placeholder="Masukkan Jumlah Tarif Penalti"
          value={outsideOpsFee ?? ''}
          onChange={handleValueInput}
          onWheel={numberInputOnWheelPreventChange}
          // min={0}
          onKeyDown={handleKeyDown}
        />

        <SelectFieldDropdown
          label="Tipe Sewa"
          htmlFor="rent-type"
          data={SERVICE_DATA}
          placeholder="Pilih Tipe Sewa"
          value={selectedService}
          onChange={handleTipeSewa}
          checkBox
        />

        <div className="garage-detail__operational-status">
          <p>Penalti Status</p>

          <div className="garage-detail__status-info">
            <div className="user__toggle-status">
              <label className="switch">
                <input type="checkbox" onChange={handlePenaltiStatus} role={'switch'} checked={isActive} />
                <span className="slider"></span>
              </label>
            </div>

            {isActive ? (
              <span className="user__status-badge user__status-badge--active">Aktif</span>
            ) : (
              <span className="user__status-badge user__status-badge--blocked">Inactive</span>
            )}
          </div>
        </div>
      </div>

      {(checkPermission(offCanvasMenu, currentMenu, 'update') || Object.keys(garageDetail).length === 0) && (
        <Button
          type="button"
          onClick={handleAddGarage}
          width={267}
          style={{ marginLeft: 'auto' }}
          className="garage-btn"
        >
          {Object.keys(garageDetail).length === 0 ? 'Tambah' : 'Simpan'}
        </Button>
      )}
    </Modals>
  );
};

export default NewGarageModal;

const SERVICE_DATA = [
  {
    id: 0,
    name: 'Dengan Supir',
    value: 'with_driver',
  },
  {
    id: 1,
    name: 'Tanpa Supir',
    value: 'without_driver',
  },
  {
    id: 2,
    name: 'Airport Transfer',
    value: 'airport_transfer',
  },
];
