import { InputField } from 'components/Global';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import TextAreaEditor from 'components/Global/TextAreaEditor';
import React, { useRef } from 'react';

const BusinessDetail = ({ locationData, data, setData, uploadAction, image }) => {
  const inputRef = useRef(null);

  const handleUploadImage = (e) => {
    if (!e.target.files.length) return;

    uploadAction(e.target.files[0]);
  };

  return (
    <div className="business-detail__form">
      <div className="form__two-column">
        <InputField
          label="Nama"
          placeholder="Masukan Nama"
          value={data.name}
          onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
        />
        <InputField
          label="Email"
          placeholder="Masukan Email"
          value={data.email}
          onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
        />
        <InputField
          label="No. Handphone"
          placeholder="Masukan No. Handphone"
          value={data.phone}
          onChange={(e) => setData((prev) => ({ ...prev, phone: e.target.value }))}
        />
        <InputField
          label="Alamat"
          placeholder="Masukan Alamat"
          value={data.address}
          onChange={(e) => setData((prev) => ({ ...prev, address: e.target.value }))}
        />
        <SelectFieldDropdown
          label="Lokasi"
          data={locationData}
          placeholder="Pilih Lokasi"
          value={data.location_id}
          onChange={(e) => setData((prev) => ({ ...prev, location_id: +e.target.id }))}
        />
        <div className="input-group__logo-input">
          <h4>Logo</h4>
          <div className="logo-upload">
            <input type="file" accept="image/*" hidden ref={inputRef} onChange={handleUploadImage} />
            {image ? (
              <p className="link-upload" onClick={() => inputRef.current.click()}>
                {image}
              </p>
            ) : (
              <button className="btn-upload" onClick={() => inputRef.current.click()}>
                Browse..
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="input-group__text-editor">
        <h4>Deskripsi</h4>
        <TextAreaEditor
          id="benefit"
          value={data.description}
          onChange={(e) => setData((prev) => ({ ...prev, description: e }))}
        />
      </div>
    </div>
  );
};

export default BusinessDetail;
