import { createSlice } from '@reduxjs/toolkit';
import { getAllMatrix, getAllMatrixVoucher } from './actions';

const initialState = {
  matrix: [],
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: '',
};

export const matrixSlice = createSlice({
  name: 'matrix',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMatrix.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllMatrix.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllMatrix.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.matrix = action.payload;
      })
      .addCase(getAllMatrixVoucher.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllMatrixVoucher.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllMatrixVoucher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.matrix = action.payload;
      });
  },
});

export default matrixSlice.reducer;
