import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import TaskInformation from './TaskInformation';
import { getTaskDetail } from 'features/driver-task/actions';
import countryCodes from 'utils/country-codes.json';
import { indonesianDateFormat } from 'utils/helpers';
import { fetchCustomerInfo } from 'features/user/actions';
import { getCustomerData } from 'features/user/slice';

export const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);

const TaskDetailWithoutDriver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedTask: data } = useSelector((state) => state.driverTask);
  const customer = useSelector(getCustomerData);

  const { taskId } = useParams();

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });

  useEffect(() => {
    if (data) {
      setSelectedCountry(findCountryByCode(data?.order?.phone_country_code));
    }
  }, [data]);

  useEffect(() => {
    if (!taskId) return;
    dispatch(getTaskDetail(taskId));
  }, [taskId]);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    dispatch(fetchCustomerInfo(data?.order?.customer_id));
  }, [data]);

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <div className="task-detail">
      <div className="task-detail__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Driver 1">
        <div className="task-detail__renter-detail">
          <div className="task-detail__renter-detail-title">
            <h1>Detail Penyewa</h1>
          </div>
          <div className="task-detail__renter-detail-body">
            <InputField label="Nama Lengkap" disabled value={data?.order?.user_name || '-'} />
            <InputField label="No Order" disabled value={data?.order?.order_key || '-'} />

            <div className="task-detail__renter-detail-body__phone-number">
              <InputField
                label="No Handphone"
                disabled
                value={data?.order?.is_admin_creation ? data?.order?.phone_country_code : customer.phone_code || '-'}
                icon={
                  <img
                    src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                    width={26}
                    height={16}
                    alt={selectedCountry?.name + ' nation flag'}
                  />
                }
              />
              <InputField value={data?.order?.phone_number || '-'} disabled />
            </div>

            <InputField label="Plat Nomor" disabled value={data?.order?.order_detail.vehicle.license_number || '-'} />
            <InputField
              label="Jumlah Penumpang"
              disabled
              value={`${data?.order?.order_detail.vehicle.max_passanger} orang` || '-'}
            />
            <InputField label="Mobil" disabled value={data?.order?.order_detail.vehicle.name || '-'} />
          </div>
        </div>

        <div className="task-detail__rent-detail">
          <div className="task-detail__rent-detail-title">
            <h1>Detail Sewa</h1>
          </div>
          <div className="task-detail__rent-detail-body">
            <InputField
              label="Lokasi Pengantaran"
              disabled
              value={data?.order?.order_detail.rental_delivery_location || '-'}
            />
            <InputField
              label="Detail Lokasi Pengantaran"
              disabled
              value={data?.order?.order_detail.rental_delivery_location_detail || '-'}
            />

            <InputField
              label="Lokasi Pengembalian"
              disabled
              value={data?.order?.order_detail.rental_return_location || '-'}
            />
            <InputField
              label="Detail Lokasi Pengembalian"
              disabled
              value={data?.order?.order_detail.rental_return_location_detail || '-'}
            />

            <div className="task-detail__rent-detail-body__rent-date">
              <InputField
                label="Tanggal Sewa"
                disabled
                value={
                  data?.order?.order_detail?.start_booking_date
                    ? indonesianDateFormat(data?.order?.order_detail?.start_booking_date)
                    : '-'
                }
              />
              <InputField label="Jam Mulai" disabled value={data?.order?.order_detail?.start_booking_time || '-'} />
            </div>

            <div className="task-detail__rent-detail-body__rent-date">
              <InputField
                label="Tanggal Selesai"
                disabled
                value={
                  data?.order?.order_detail?.end_booking_date
                    ? indonesianDateFormat(data?.order?.order_detail?.end_booking_date)
                    : '-'
                }
              />
              <InputField label="Jam Selesai" disabled value={data?.order?.order_detail?.end_booking_time || '-'} />
            </div>
          </div>
        </div>

        <div className="task-detail__identity">
          <div style={{ width: '100%' }}>
            <div className="task-detail__identity--title">
              <h4>Foto KTP</h4>
            </div>

            {data?.order?.order_detail.identity.ktp ? (
              <div className="task-detail__image-container">
                <img src={data?.order?.order_detail.identity.ktp || ''} alt="foto KTP" />
              </div>
            ) : (
              <div style={{ height: '164px' }}>
                <p>Belum Upload KTP</p>
              </div>
            )}
          </div>

          <div style={{ width: '100%' }}>
            <div className="task-detail__identity--title">
              <h4>Foto SIM</h4>
            </div>

            {data?.order?.order_detail.identity.sim ? (
              <div className="task-detail__image-container">
                <img src={data?.order?.order_detail.identity.sim || ''} alt="foto sim" />
              </div>
            ) : (
              <div style={{ height: '164px' }}>
                <p>Belum Upload SIM</p>
              </div>
            )}
          </div>
        </div>
      </TableWrapper>

      {data?.histories?.map((item) => {
        if (item.title === 'Ambil Dari Garasi') {
          return (
            <TaskInformation title="Ambil Dari Garasi" style={{ marginTop: '32px' }} item={item} key={item.title} />
          );
        } else if (item.title === 'Antar Mobil') {
          return <TaskInformation title="Antar Mobil" style={{ marginTop: '32px' }} item={item} key={item.title} />;
        } else if (item.title === 'Ambil Mobil') {
          return <TaskInformation title="Ambil Mobil" style={{ marginTop: '32px' }} item={item} key={item.title} />;
        } else if (item.title === 'Parkir ke Garasi') {
          return (
            <TaskInformation title="Parkir ke Garasi" style={{ marginTop: '32px' }} item={item} key={item.title} />
          );
        } else {
          return null;
        }
      })}
      <div className="task-detail__booking-zone__back-btn-wrapper task-detail__booking-zone__back-btn-wrapper__without-driver">
        <Button variant="outline" width={252} onClick={() => navigate(-1)}>
          Kembali
        </Button>
      </div>

      {/* <TaskInformation title="Ambil Dari Garasi" style={{ marginTop: '32px' }} photos={data.} />
      <TaskInformation title="Antar Mobil" style={{ marginTop: '32px' }} />
      <TaskInformation title="Ambil Mobil" violations style={{ marginTop: '32px' }} />
      <TaskInformation title="Parkir ke Garasi" style={{ marginTop: '32px' }} /> */}
    </div>
  );
};

export default TaskDetailWithoutDriver;
