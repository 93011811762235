import React from 'react';

const TablePoint = ({ data, columns = [], colorRank, isLoading = false }) => {
  if (isLoading || !data || data.length === 0) {
    return (
      <div className="table-point-skeleton">
        <div
          className="table-point-skeleton__head"
          style={{ display: 'grid', gridTemplateColumns: `${columns.map(() => '1fr').join(' ')}` }}
        >
          {Array.from({ length: columns.length }, (_, i) => i + Math.random()).map((item) => (
            <div key={item} />
          ))}
        </div>
        <div className="table-point-skeleton__body">
          {Array.from({ length: data?.length }, (_, i) => i + Math.random()).map((item) => (
            <div key={item}></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <table className="table-point">
      <thead>
        {columns.map((item) => (
          <th key={item.id}>{item.header}</th>
        ))}
      </thead>
      <tbody>
        {data?.map((item, idx) => (
          <tr key={idx}>
            {columns.map((col) => {
              if (col.header === 'No.') {
                return (
                  <td key={col.header}>
                    <div
                      style={{
                        color: colorRank
                          ? idx === 0
                            ? 'white'
                            : idx === 1
                            ? 'white'
                            : idx === 2
                            ? 'white'
                            : 'black'
                          : 'black',
                        background: colorRank
                          ? idx === 0
                            ? '#E9B522'
                            : idx === 1
                            ? '#D1D1D1'
                            : idx === 2
                            ? '#CD6F00'
                            : ''
                          : '',
                      }}
                    >
                      {idx + 1}
                    </div>
                  </td>
                );
              } else if (col.value === 'user_name_ranking') {
                return (
                  <td key={col.header} style={{ width: '20%' }}>
                    {item[col.value]}
                  </td>
                );
              } else if (col.value === 'referral_ranking') {
                return (
                  <td key={col.header} style={{ width: '35%' }}>
                    {item[col.value]}
                  </td>
                );
              } else {
                return <td key={col.header}>{item[col.value]}</td>;
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TablePoint;
