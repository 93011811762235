import React, { useEffect } from 'react';
import DetailPenyewa from '../DetailPenyewa';
import DetailSewa from '../DetailSewa';
import { TableWrapper } from 'components/Global';
import { ReactComponent as VehicleLogoIcon } from '../../../../../icons/custom-order-filled-icon.svg';
import DetailPembayaran from './DetailPembayaran';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from 'features/orders/actions';
import MetodePembayaran from '../MetodePembayaran';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetData } from 'features/orders/createOrder';
import { overtime as overtimeWithDriver } from '../DetailSewa/WithDriverForm';
import { ReactComponent as AirportLogoIcon } from 'icons/airport-transfer-filled-icon.svg';

// STATE THAT CONFIRMATIONING IF THE PAGE IS CONFIRMATION ORDER
const isOrderConfirmation = true;

const KonfirmasiPesanan = ({
  nextPage,
  prevPage,
  orderData,
  setOrderData,
  // detail penyewa //
  depositBank,
  setDepositBank,
  depositAccountNumber,
  setDepositAccountNumber,
  ktpImageLink,
  setKtpImageLink,
  simImageLink,
  setSimImageLink,
  // detail sewa //
  vehicleInput,
  setVehicleInput,
  selectedCar,
  setSelectedCar,
  startRentDate,
  setStartRentDate,
  endRentDate,
  setEndRentDate,
  deliveryLocation,
  setDeliveryLocation,
  setReturnLocation,
  rentalLocation,
  setRentalLocation,
  refferalCode,
  setRefferalCode,
  returnLocation,
  driver,
  setDriver,
  allVehicle,
  selectedPaymentType,
  setSelectedPaymentType,
  // metode pembayaran //
  paymentImageLink,
  setPaymentImageLink,
  selectedOvertime,
  setSelectedOvertime,
  // with driver//
  durationRent,
  setDurationRent,
  dayDuration,
  setDayDuration,
  allZonesList,
  orderDataLocalStorage,
  setOrderDataLocalStorage,
  // airport transfer//
  isSwitch,
  setIsSwitch,
  // shuttleData,
  rentalLocationId,
  setRentalLocationId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pathName = location.pathname.split('/').filter((item) => item !== '')[0];

  // CONTEXT
  const { showToast, setShowConfirmation, setShowSpinner } = useAppContext();
  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  // GLOBAL STATE
  const { status } = useSelector((state) => state.createOrder);

  // SUBMIT AND CREATE ORDER
  const submitHandler = (e) => {
    e.preventDefault();

    let payload;

    if (pathName === 'airport-transfer') {
      const orderPayload = { ...orderData };
      delete orderPayload.order_detail.additionals;
      delete orderPayload.order_detail.without_driver;
      delete orderPayload.deposit;
      delete orderPayload.over_time_price;
      delete orderPayload.over_time;
      delete orderPayload.order_violations;
      delete orderPayload.remainder;
      delete orderPayload.down_payment;
      delete orderPayload.exceed_passenger_price;

      payload = {
        ...orderPayload,
        order_detail: {
          ...orderPayload.order_detail,
          landing_time: orderData.order_detail.start_booking_time,
          end_booking_time: orderData.order_detail.start_booking_time,
          end_booking_date: orderData.order_detail.start_booking_date,
          loc_time_id: rentalLocationData?.find((item) => item.id === rentalLocationId.id)?.time_zone_identifier,
          location_id: rentalLocationId?.id,
          dp_rule_id: summaryOrderData?.minimal_dp_rule?.id,
          baggage: isNaN(orderData.order_detail.baggage) ? 0 : orderData.order_detail.baggage,
        },
        wa_number: orderPayload.wa_number,
        phone_number: orderPayload.phone_number,
        type: 'FULL',
        price_per_day: summaryOrderData?.price_per_day,
        outside_operational_charge: summaryOrderData?.outside_operational_charge,
      };
    }

    if (pathName === 'with-driver' || pathName === 'without-driver') {
      payload = {
        ...orderData,
        wa_number: orderData.wa_number,
        phone_number: orderData.phone_number,
        deposit: isNaN(orderData.deposit) ? 0 : orderData.deposit,
        order_violations:
          orderData.order_violations.length === 0
            ? orderData.order_violations
            : orderData.order_violations.map((item) => {
                return { violation: item.violation, cost: item.cost };
              }),
        order_detail: {
          ...orderData.order_detail,
          booking_zones: orderData?.order_detail?.booking_zones?.map((item, idx) => ({
            ...item,
            driving_zone_price: summaryOrderData.order_zone_price[idx].driving_zone_price,
            drop_off_zone_price: summaryOrderData.order_zone_price[idx].drop_off_zone_price,
            pickup_zone_price: summaryOrderData.order_zone_price[idx].pick_up_zone_price,
            total_price: summaryOrderData.order_zone_price[idx].total_price,
            outside_operational_charge: summaryOrderData.order_zone_price[idx].outside_operational_charge,
            booking_start_time: dayDuration[idx].booking_start_time,
            booking_end_time:
              overtimeWithDriver.find((ovt) => ovt.id === dayDuration[idx].overtime_duration)?.value > 0
                ? `${
                    dayDuration[idx].booking_end_time.split(':')[0] -
                    overtimeWithDriver.find((ovt) => ovt.id === dayDuration[idx].overtime_duration)?.value
                  }:${dayDuration[idx].booking_end_time.split(':')[1]}`
                : dayDuration[idx].booking_end_time,
            overtime_duration:
              overtimeWithDriver.find((ovt) => ovt.id === dayDuration[idx].overtime_duration)?.value || 0,
            driver_stay_overnight_price: summaryOrderData.order_zone_price[idx].driver_stay_overnight_price,
          })),
          end_booking_time:
            pathName === 'without-driver'
              ? orderData.order_detail.end_booking_time
              : overtimeWithDriver.find((ovt) => ovt.id === dayDuration[dayDuration.length - 1].overtime_duration)
                  ?.value > 0
              ? `${
                  dayDuration[dayDuration.length - 1].booking_end_time.split(':')[0] -
                  overtimeWithDriver.find((ovt) => ovt.id === dayDuration[dayDuration.length - 1].overtime_duration)
                    ?.value
                }:${dayDuration[dayDuration.length - 1].booking_end_time.split(':')[1]}`
              : dayDuration[dayDuration.length - 1]?.booking_end_time,
          start_booking_time:
            pathName === 'without-driver'
              ? orderData.order_detail.start_booking_time
              : dayDuration[0]?.booking_start_time,
          loc_time_id: rentalLocationData?.find((item) => item.id === rentalLocationId.id)?.time_zone_identifier,
          location_id: rentalLocationId?.id,
          dp_rule_id: summaryOrderData?.minimal_dp_rule?.id,
          baggage: isNaN(orderData.order_detail.baggage) ? 0 : orderData.order_detail.baggage,
        },
        over_time_price: pathName === 'without-driver' ? summaryOrderData.over_time_price : undefined,
        over_time: pathName === 'without-driver' ? orderData.over_time : undefined,
        order_overtime_total_price:
          pathName === 'with-driver' ? summaryOrderData.order_overtime_total_price : undefined,
        remainder: orderData.type === 'HALF' ? summaryOrderData?.remainder : 0,
        price_per_day: summaryOrderData?.price_per_day,
        exceed_passenger_price: summaryOrderData?.exced_max_passenger_charge,
        down_payment: orderData.type === 'HALF' ? summaryOrderData?.total_dp : 0,
        one_day_order_charge: summaryOrderData?.one_day_order_charge,
        outside_operational_charge: summaryOrderData?.outside_operational_charge,
      };
    }

    if (summaryOrderData?.promotion) {
      payload.promotion_id = summaryOrderData.promotion.id;
    }

    setShowConfirmation({
      message: 'Apakah anda yakin ingin lanjut melakukan proses transaksi?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(createOrder(payload)).unwrap();

          if (pathName === 'with-driver') {
            navigate(`/with-driver?status=ALL&locationId=${rentalLocationId.id}`);
          } else if (pathName === 'without-driver') {
            navigate(`/without-driver?status=ALL&locationId=${rentalLocationId.id}`);
          } else if (pathName === `airport-transfer`) {
            navigate(`/airport-transfer?status=ALL&locationId=${rentalLocationId.id}`);
          }

          showToast({ type: 'success', message: 'Berhasil Proses Transaksi' });
          dispatch(resetData());
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Proses Transaksi Gagal' });
        }
      },
    });
  };

  // SHOW LOADING SPINNER
  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <div className="order-confirmation">
      <TableWrapper
        icon={
          pathName === 'airport-transfer' ? (
            <AirportLogoIcon width="25px" height="25px" />
          ) : (
            <VehicleLogoIcon width="25px" height="25px" />
          )
        }
        title={pathName === 'airport-transfer' ? 'Custom Airport Transfer' : 'Custom Sewa Mobil'}
      >
        <DetailPenyewa
          nextPage={nextPage}
          orderData={orderData}
          setOrderData={setOrderData}
          depositBank={depositBank}
          setDepositBank={setDepositBank}
          depositAccountNumber={depositAccountNumber}
          setDepositAccountNumber={setDepositAccountNumber}
          ktpImageLink={ktpImageLink}
          setKtpImageLink={setKtpImageLink}
          simImageLink={simImageLink}
          setSimImageLink={setSimImageLink}
          isOrderConfirmation={isOrderConfirmation}
          orderDataLocalStorage={orderDataLocalStorage}
          setOrderDataLocalStorage={setOrderDataLocalStorage}
        />
      </TableWrapper>
      <div style={{ background: 'white', padding: '1px 16px 18px', margin: '22px 0', borderRadius: '4px' }}>
        <DetailSewa
          prevPage={prevPage}
          nextPage={nextPage}
          orderData={orderData}
          setOrderData={setOrderData}
          vehicleInput={vehicleInput}
          setVehicleInput={setVehicleInput}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          startRentDate={startRentDate}
          setStartRentDate={setStartRentDate}
          endRentDate={endRentDate}
          setEndRentDate={setEndRentDate}
          deliveryLocation={deliveryLocation}
          setDeliveryLocation={setDeliveryLocation}
          returnLocation={returnLocation}
          setReturnLocation={setReturnLocation}
          rentalLocation={rentalLocation}
          setRentalLocation={setRentalLocation}
          refferalCode={refferalCode}
          setRefferalCode={setRefferalCode}
          driver={driver}
          setDriver={setDriver}
          isOrderConfirmation={isOrderConfirmation}
          allVehicle={allVehicle}
          selectedOvertime={selectedOvertime}
          setSelectedOvertime={setSelectedOvertime}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          // with driver//
          durationRent={durationRent}
          setDurationRent={setDurationRent}
          dayDuration={dayDuration}
          setDayDuration={setDayDuration}
          allZonesList={allZonesList}
          orderDataLocalStorage={orderDataLocalStorage}
          setOrderDataLocalStorage={setOrderDataLocalStorage}
          // airport transfer//
          isSwitch={isSwitch}
          setIsSwitch={setIsSwitch}
          // shuttleData={shuttleData}
          rentalLocationId={rentalLocationId}
          setRentalLocationId={setRentalLocationId}
        />
      </div>
      <div style={{ background: 'white', padding: '1px 16px 18px', margin: '22px 0', borderRadius: '4px' }}>
        <MetodePembayaran
          orderData={orderData}
          setOrderData={setOrderData}
          paymentImageLink={paymentImageLink}
          setPaymentImageLink={setPaymentImageLink}
          isOrderConfirmation={isOrderConfirmation}
          orderDataLocalStorage={orderDataLocalStorage}
          setOrderDataLocalStorage={setOrderDataLocalStorage}
        />
      </div>
      <DetailPembayaran
        prevPage={prevPage}
        nextPage={nextPage}
        orderData={orderData}
        setOrderData={setOrderData}
        returnLocation={returnLocation}
        deliveryLocation={deliveryLocation}
        selectedCar={selectedCar}
        submitHandler={submitHandler}
        durationRent={durationRent}
        orderDataLocalStorage={orderDataLocalStorage}
        setOrderDataLocalStorage={setOrderDataLocalStorage}
      />
    </div>
  );
};

export default KonfirmasiPesanan;
