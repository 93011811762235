import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import { useSelector } from 'react-redux';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
// import { getRefferalReportGraphic } from 'features/refferal-report/actions';
// import { format, subYears } from 'date-fns';

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      backgroundColor: 'white',
      titleColor: 'black',
      bodyColor: 'black',
      borderColor: 'black',
      borderWidth: 0.5,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 12,
          weight: 600,
        },
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
      max: 500000,
      min: 0,
      ticks: {
        stepSize: 50000,
        font: {
          size: 12,
          weight: 600,
        },
      },
      border: {
        display: false,
      },
    },
  },
};

const LABELS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

const GrafikPoint = () => {
  const { data: graphicData } = useSelector((state) => state.refferalReportGraphic);

  const [pointData, setPointData] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function ({ dataset, dataIndex }) {
            return setCurrency(dataset.data[dataIndex]).replace('Rp', '');
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            weight: 600,
          },
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        max:
          pointData.map((item) => item.point).sort((a, b) => a - b)[pointData.length - 1] < 10
            ? 100
            : pointData.map((item) => item.point).sort((a, b) => a - b)[pointData.length - 1],
        min: 0,
        ticks: {
          stepSize:
            pointData.map((item) => item.point).sort((a, b) => a - b)[pointData.length - 1] < 10
              ? 15
              : pointData.map((item) => item.point).sort((a, b) => a - b)[pointData.length - 1] / 8,
          font: {
            size: 12,
            weight: 600,
          },
          callback: function (value) {
            if (value === 0) {
              return value;
            } else {
              const num = +value.toFixed(0);
              return this.getLabelForValue(num);
            }
          },
        },
        border: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: LABELS,
    datasets: [
      {
        data: pointData.map((item) => item.point),
        borderRadius: 100,
        barThickness: 10,
        backgroundColor: 'rgba(0, 158, 247, 1)',
        borderSkipped: false,
      },
    ],
  };

  // FUNCTION TO ADD BACKGROUND BEHIND THE CHART BAR
  const barPattern = {
    id: 'barPattern',
    beforeDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { top, height, bottom },
        scales: { x },
      } = chart;

      const width = chart.getDatasetMeta(0).data[0]?.width;
      const radius = data.datasets[0].borderRadius;

      chart.getDatasetMeta(0).data.forEach((__, index) => {
        ctx.beginPath();
        ctx.fillStyle = '#F2F7FF';

        ctx.roundRect(x.getPixelForValue(index) - width / 2, top, width, height, radius);
        ctx.fill();

        ctx.fillRect(x.getPixelForValue(index) - width / 2, bottom, width, -50);
      });
    },
  };

  useEffect(() => {
    if (!graphicData || checkEmptyObject(graphicData)) return;

    const result = LABELS.map((month, index) => {
      const foundData = graphicData.find((item) => item.month === index + 1);
      return {
        year: foundData ? foundData.year : '2024',
        month: index + 1,
        month_name: month,
        point: foundData ? foundData.point : 0,
      };
    });

    setPointData(result);
  }, [graphicData]);

  return (
    <CardDashboardItem title="Grafik Point" separator asChild>
      <Bar options={options} data={data} plugins={[barPattern]} height={413} />
    </CardDashboardItem>
  );
};

export default GrafikPoint;
