import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL COURIERS - WITHOUT DRIVER
export const getAllCouriers = createAsyncThunk('couriers/getAllCouriers', async (transaction_key, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(
      `${BASE_URL}/${API_VERSION}/user-courier-avaibility?order_transaction_key=${transaction_key}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

// GET ALL DRIVERS - WITH DRIVER
export const getAllDrivers = createAsyncThunk('couriers/getAllDrivers', async (transaction_key, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(
      `${BASE_URL}/${API_VERSION}/user-driver-avaibility?order_transaction_key=${transaction_key}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

// GET DETAIL TASK COURIER
export const getDetailTaskCourier = createAsyncThunk(
  'couriers/getDetailTaskCourier',
  async (transaction_key, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}/tasks`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.data;
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

// GET DETAIL TASK COURIER
export const getCourierDetail = createAsyncThunk('couriers/getCourierDetail', async (user_id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/users/${user_id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

// ASSIGN COURIER TO TASK
export const assignCourier = createAsyncThunk('couriers/assignCourier', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/tasks`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
