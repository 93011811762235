import React, { useEffect, useState } from 'react';
import { getMonth } from 'utils';
import Month from './Month';
import Sidebar from './Sidebar';
import Header from './Header';
import { useAppContext } from 'components/Context/AppContext';
import TaskModal from './TaskModal';
import { useDispatch } from 'react-redux';
import { getDriverTask } from 'features/driver-task/actions';

const DriverTask = () => {
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showTaskModal } = useAppContext();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  useEffect(() => {
    dispatch(getDriverTask());
  }, []);

  return (
    <>
      {showTaskModal && <TaskModal />}
      <div className="driver-task__wrapper">
        <div className="driver-task__container">
          <Header />
          <div className="driver-task__body">
            <Month month={currenMonth} />
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverTask;
