import React from 'react';
import Tabs, { TabPane } from 'components/Global/Tabs';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { Button } from 'components/Global';
import BusinessList from './business-list';
import EmployeeList from './employee-list';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { useDispatch } from 'react-redux';
import { resetSelectedBusiness } from 'features/business-partner/slice';
import { getAllBusinessPartners } from 'features/business-partner/actions';

const BusinessPartnerComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // LOCAL STATE
  const [activeTab, setActiveTab] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  // GET THE PARAMS TAB ACTIVE WHEN PAGE RELOAD
  const tabActive = searchParams.get('tab-active');

  const handleAdd = () => {
    dispatch(resetSelectedBusiness());
    navigate(`/business-partner/detail?tab=${tabActive}`);
  };

  // SET ACTIVE TAB WHEN PAGE RELOAD
  useEffect(() => {
    if (!tabActive) {
      setSearchParams({ 'tab-active': 'Perusahaan' });
    } else {
      setActiveTab(tabActive);
    }
  }, [tabActive]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllBusinessPartners());
  }, []);

  return (
    <div className="promo">
      {/* TABLE */}
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabPane label="Perusahaan">
          <BusinessList />
        </TabPane>
        <TabPane label="Karyawan">
          <EmployeeList />
        </TabPane>
      </Tabs>

      {/* BUTTON */}
      <Button onClick={handleAdd} width="192px" height="39px" className="promo__button-add">
        <PlusIcon />
        <span className="button-label">Tambah {activeTab}</span>
      </Button>
    </div>
  );
};

export default BusinessPartnerComponent;
