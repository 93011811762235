import dayjs from 'dayjs';
import React from 'react';
import 'dayjs/locale/id';
import { setCurrency } from 'utils/functionality';

const TaskInformation = ({ title, item, style }) => {
  // <div className="task-detail__task-information-empty">
  //     <h3>{title}</h3>

  //     <div>
  //       <p>Tugas belum diselesaikan driver</p>
  //     </div>
  //   </div>
  // console.log(dayjs(item.date_time).format('dddd, DD MMMM YYYY'));

  // Set locale to Indonesian
  dayjs.locale('id');

  let totalCost = 0;

  if (item.violations) {
    item.violations.forEach((item) => {
      totalCost += item.cost;
    });
  }

  return (
    <div className="task-detail__task-information-section" style={style}>
      <h3>{title}</h3>
      <p className="task-detail__task-information-section__date">
        {dayjs(item.date_time).format('dddd, DD MMMM YYYY | HH:mm:ss')}
      </p>

      <div className="task-detail__foto-gallery">
        {item?.photos?.map((photo, idx) => (
          <div style={{ width: '100%' }} key={idx}>
            <div className="task-detail__identity--title">
              <h4>Foto Mobil</h4>
            </div>

            <div className="task-detail__image-container">
              <img src={photo} alt="foto mobil" />
            </div>
          </div>
        ))}
      </div>

      <div className="task-detail__note">
        <h4 className="task-detail__note__title">Keterangan</h4>

        <div className="task-detail__note__box">
          <p>{item?.note || '-'}</p>
        </div>
      </div>

      {item.violations && (
        <div className="task-detail__violations">
          <h4 className="task-detail__violations__title">Jenis Pelanggaran</h4>

          <div className="task-detail__violations__box">
            <ul className="task-detail__violations__violation-list">
              {item.violations.map((item) => (
                <li key={item.id} className="task-detail__violations__violation-item">
                  <div>{item.violation}</div>
                  <div>{setCurrency(item.cost)}</div>
                </li>
              ))}
            </ul>

            <div className="task-detail__violations__violation-total">
              <div>Total</div>
              <div>{setCurrency(totalCost)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskInformation;
