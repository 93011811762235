import { createSlice } from '@reduxjs/toolkit';
import { getAllVouchers, getVoucherDetail } from './actions';

const initialState = {
  data: [],
  selected: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
};

export const vouchers = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    resetSelectedVoucher: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVouchers.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllVouchers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllVouchers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = '';
        state.data = action.payload;
      })
      .addCase(getVoucherDetail.pending, (state) => {
        state.selected = {};
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getVoucherDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVoucherDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selected = action.payload;
      });
  },
});

export const { resetSelectedVoucher } = vouchers.actions;
export default vouchers.reducer;
