import dayjs from 'dayjs';
import React from 'react';

const TaskInformation = ({ title, violations, item }) => {
  // <div className="task-detail__task-information-empty">
  //     <h3>{title}</h3>

  //     <div>
  //       <p>Tugas belum diselesaikan driver</p>
  //     </div>
  //   </div>

  return (
    <div className="task-detail__task-information-section">
      <h3>{title}</h3>
      <p className="task-detail__task-information-section__date">
        {dayjs(item?.date_time).format('dddd, DD MMMM YYYY | HH:mm:ss')}
      </p>

      <div className="task-detail__foto-gallery">
        {item?.photos.map((photo, idx) => (
          <div key={idx} style={{ width: '100%' }}>
            <div className="task-detail__identity--title">
              <h4>Foto Mobil</h4>
            </div>

            <div className="task-detail__image-container">
              <img src={photo} alt="foto Mobil" />
            </div>
          </div>
        ))}
      </div>

      <div className="task-detail__note">
        <h4 className="task-detail__note__title">Keterangan</h4>

        <div className="task-detail__note__box">
          <p>{item?.note || '-'}</p>
        </div>
      </div>

      {violations && (
        <div className="task-detail__violations">
          <h4 className="task-detail__violations__title">Jenis Pelanggaran</h4>

          <div className="task-detail__violations__box">
            <ul className="task-detail__violations__violation-list">
              <li className="task-detail__violations__violation-item">
                <div>Lecet 2 titik</div>
                <div>Rp250.000</div>
              </li>
              <li className="task-detail__violations__violation-item">
                <div>Penyok</div>
                <div>Rp250.000</div>
              </li>
            </ul>

            <div className="task-detail__violations__violation-total">
              <div>Total</div>
              <div>Rp250.000</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskInformation;
