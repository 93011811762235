import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL VOUCHERS
export const getAllVouchers = createAsyncThunk('vouchers/getAllVouchers', async (page, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/vouchers?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// GET VOUCHER BY ID
export const getVoucherDetail = createAsyncThunk('vouchers/getVoucherDetail', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/vouchers/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to get voucher detail');
  }
});

// DELETE VOUCHER BY ID
export const deleteVoucherById = createAsyncThunk('vouchers/deleteVoucherById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/vouchers/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to delete voucher');
  }
});

// CREATE VOUCHER
export const createNewVoucher = createAsyncThunk('vouchers/createNewVoucher', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/vouchers`,
      {
        ...payload,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to create voucher');
  }
});

// EDIT VOUCHER BY ID
export const editVoucherById = createAsyncThunk('vouchers/editVoucherById', async ({ payload, id }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/vouchers/${id}`,
      {
        ...payload,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to edit voucher');
  }
});

// UPLOAD BANNER VOUCHER
export const uploadBannerVoucher = createAsyncThunk('vouchers/uploadBannerVoucher', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/vouchers/upload`,
      { file: payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const data = await response.data;
    return data.file;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to upload banner voucher');
  }
});
