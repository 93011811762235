import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkEmptyObject } from 'utils/functionality';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import TablePoint from './TablePoint';

const COLUMNS = [
  { header: 'No.', value: 'no' },
  { header: 'Nama', value: 'user_name_ranking' },
  { header: 'Email', value: 'email' },
  { header: 'Kode Refferal', value: 'referral_ranking' },
  { header: 'Point', value: 'point' },
];

const RankingPoint = () => {
  const { data, isLoading } = useSelector((state) => state.refferalReportRanking);
  const [rankingData, setRankingData] = useState([]);

  useEffect(() => {
    if (!data || checkEmptyObject(data)) return;

    const newData = data.map((item) => ({
      ...item,
      user_name_ranking: item.user_name,
      referral_ranking: item.referral,
    }));

    setRankingData(newData);
  }, [data]);

  return (
    <CardDashboardItem title="Ranking Points" asChild separator>
      <TablePoint data={rankingData} columns={COLUMNS} isLoading={isLoading} colorRank />
    </CardDashboardItem>
  );
};

export default RankingPoint;
