import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getRefferalReportTotalPoint = createAsyncThunk(
  'refferalReportSlice/getRefferalReportTotalPoint',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { startDate, endDate } = params;

    let newUrl = `${BASE_URL}/${API_VERSION}/admin/dashboard/reports/referral?type=total-point`;

    if (startDate) {
      newUrl += `&start=${startDate}`;
    }

    if (endDate) {
      newUrl += `&end=${endDate}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getRefferalReportGraphic = createAsyncThunk(
  'refferalReportSlice/getRefferalReportGraphic',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { startDate, endDate } = params;

    let newUrl = `${BASE_URL}/${API_VERSION}/admin/dashboard/reports/referral?type=graphic-month`;

    if (startDate) {
      newUrl += `&start=${startDate}`;
    }

    if (endDate) {
      newUrl += `&end=${endDate}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getRefferalReportLatest = createAsyncThunk(
  'refferalReportSlice/getRefferalReportLatest',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { startDate, endDate } = params;

    let newUrl = `${BASE_URL}/${API_VERSION}/admin/dashboard/reports/referral?type=latest-trx`;

    if (startDate) {
      newUrl += `&start=${startDate}`;
    }

    if (endDate) {
      newUrl += `&end=${endDate}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getRefferalReportRanking = createAsyncThunk(
  'refferalReportSlice/getRefferalReportRanking',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { startDate, endDate } = params;

    let newUrl = `${BASE_URL}/${API_VERSION}/admin/dashboard/reports/referral?type=ranking`;

    if (startDate) {
      newUrl += `&start=${startDate}`;
    }

    if (endDate) {
      newUrl += `&end=${endDate}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
