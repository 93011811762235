import { Button } from 'components/Global';
import { addDays, addHours, format, parseISO } from 'date-fns';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCurrency } from 'utils/functionality';
import { addedHourPeriod, indonesianDateFormat } from 'utils/helpers';

// PRODUCT INFO ARRAY
const productInfoList = [
  {
    id: 'pil1',
    title: 'Lokasi Pengantaran',
  },
  {
    id: 'pil2',
    title: 'Lokasi Pengembalian',
  },
  {
    id: 'pil3',
    title: 'Tanggal Mulai Sewa',
  },
  {
    id: 'pil4',
    title: 'Jam Mulai',
  },
  {
    id: 'pil5',
    title: 'Tanggal Selesai',
  },
  {
    id: 'pil6',
    title: 'Jam Selesai',
  },
  {
    id: 'pil7',
    title: 'Overtime',
  },
];

// PRODUCT INFO ARRAY WITH DRIVER
const productInfoListWithDriver = [
  {
    id: 'pilwd1',
    title: 'Tanggal Mulai Sewa',
  },
  {
    id: 'pilwd2',
    title: 'Tanggal Selesai',
  },
  {
    id: 'pilwd3',
    title: 'Jam Mulai',
  },
  {
    id: 'pilwd4',
    title: 'Durasi Sewa',
  },
];

// PRODUCT INFO ARRAY WITH DRIVER
const productInfoListAirportTransfer = [
  {
    id: 'pilat1',
    title: 'Lokasi Penjemputan',
  },
  {
    id: 'pilat2',
    title: 'Lokasi Pengantaran',
  },
  {
    id: 'pilat3',
    title: 'Tanggal Mulai Sewa',
  },
  {
    id: 'pilat4',
    title: 'Jam Mulai',
  },
];

// ORDER RENT PRICE ARRAY
const orderBillList = [
  {
    id: 'obl1',
    title: 'Harga Sewa',
  },
  {
    id: 'obl2',
    title: 'Biaya Pengantaran',
  },
  {
    id: 'obl3',
    title: 'Biaya Pengembalian',
  },
];

// ORDER RENT PRICE ARRAY WITH DRIVER
const orderBillListWithDriver = [
  {
    id: 'oblwd1',
    title: 'Harga Sewa',
  },
];

// ORDER RENT PRICE ARRAY WITH DRIVER
const orderBillListAiportTransfer = [
  {
    id: 'oblat1',
    title: 'Harga Mobil',
  },
  {
    id: 'oblat2',
    title: 'Biaya Penjemputan',
  },
  {
    id: 'oblat3',
    title: 'Biaya Pengantaran',
  },
];

// ANOTHER BILL ARRAY
const anotherBillList = [
  {
    id: 'abl1',
    title: 'Biaya Layanan',
  },
  {
    id: 'abl2',
    title: 'Biaya Asuransi',
  },
  // {
  //   id: 'abl3',
  //   title: 'Kursi Bayi',
  // },
  {
    id: 'abl4',
    title: 'Promo',
  },
  {
    id: 'abl5',
    title: 'Penumpang Tambahan (1 Orang)',
  },
  {
    id: 'abl6',
    title: 'Deposit',
  },
  {
    id: 'abl7',
    title: 'Deposit e-Toll',
  },
  {
    id: 'abl8',
    title: 'One Day Charge',
  },
  {
    id: 'abl9',
    title: 'Outside Operational Hour Charge',
  },
  {
    id: 'abl10',
    title: 'Overtime',
  },
];

const anotherBillListWithDriver = [
  {
    id: 'ablwd1',
    title: 'Biaya Layanan',
  },
  {
    id: 'ablwd2',
    title: 'Biaya Asuransi',
  },
  // {
  //   id: 'abl3',
  //   title: 'Kursi Bayi',
  // },
  {
    id: 'abl4',
    title: 'Promo',
  },
  {
    id: 'ablwd5',
    title: 'Penumpang Tambahan (1 Orang)',
  },
  {
    id: 'ablwd6',
    title: 'Deposit',
  },
  // {
  //   id: 'ablwd7',
  //   title: 'One Day Charge',
  // },
  {
    id: 'ablwd7',
    title: 'Outside Operational Hour Charge',
  },
  {
    id: 'ablwd8',
    title: 'Overtime',
  },
];

// FUNCTION MAPPING ZONE
const mappingZonehandler = (zone) => {
  // Ambil data zona dan lokasi
  const locations = [
    { zone: parseInt(zone.detail_driving_location.match(/\d+/)[0]), location: zone.detail_driving_location },
    { zone: parseInt(zone.detail_drop_off_location.match(/\d+/)[0]), location: zone.detail_drop_off_location },
    { zone: parseInt(zone.detail_pickup_location.match(/\d+/)[0]), location: zone.detail_pickup_location },
  ];

  // Urutkan berdasarkan zona
  locations.sort((a, b) => a.zone - b.zone);

  // Tampilkan hasil dalam urutan
  const sortedLocations = locations.map((location) => location.location);
  return sortedLocations.join(', ');
};

const anotherBillListAirportTransfer = [
  {
    id: 'ablat1',
    title: 'Biaya Layanan',
  },
  {
    id: 'ablat2',
    title: 'Biaya Asuransi',
  },
  {
    id: 'ablat3',
    title: 'Promo',
  },
  {
    id: 'ablat4',
    title: 'Outside Operational Hour Charge',
  },
];

const endTimePlusOvertime = (endTime, overtime) => {
  if (!endTime) return;

  const hour = endTime?.split(':')[0];
  const minute = endTime?.split(':')[1];

  const initialTime = new Date();
  initialTime.setHours(hour);
  initialTime.setMinutes(minute);

  const newTime = addHours(initialTime, overtime);

  const formattedNewTime = format(newTime, 'HH:mm');

  return formattedNewTime;
};

const endDatePlusOvertime = (endDate, endTime, overTime) => {
  const endDateWithOvertime = +endTime.split(':')[0] + overTime;
  const endDateMinute = endTime.split(':')[1];
  let newDate = endDate;

  if (endDateWithOvertime > 24) {
    const parsedDate = addDays(parseISO(`${endDate}`), 1);
    newDate = format(parsedDate, 'yyyy-MM-dd');
    return indonesianDateFormat(newDate);
  } else if (endDateWithOvertime == 24) {
    if (endDateMinute == '30') {
      const parsedDate = addDays(parseISO(`${endDate}`), 1);
      newDate = format(parsedDate, 'yyyy-MM-dd');
      return indonesianDateFormat(newDate);
    } else {
      return indonesianDateFormat(newDate);
    }
  } else {
    return indonesianDateFormat(newDate);
  }
};

const DetailPembayaran = ({
  prevPage,
  orderData,
  returnLocation,
  deliveryLocation,
  selectedCar,
  submitHandler,
  setOrderData,
  durationRent,
  orderDataLocalStorage,
  setOrderDataLocalStorage,
}) => {
  const location = useLocation();

  const pathName = location.pathname.split('/').filter((item) => item !== '')[0];

  // GLOBAL STATE
  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);
  // const { data: zoneData } = useSelector((state) => state.zone);

  // SET TOTAL PAYMENT
  useEffect(() => {
    if (Object.keys(summaryOrderData).length === 0) return;

    const newOrderData = {
      ...orderData,
      total_payment: summaryOrderData.total_payment,
      booking_price: summaryOrderData.booking_price,
      insurance_fee: summaryOrderData.insurance_fee,
      service_fee: summaryOrderData.service_fee,
    };

    setOrderData(newOrderData);
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
  }, [summaryOrderData]);

  return (
    <div className="detail-payment">
      <h2 className="detail-payment__title">Detail Pembayaran</h2>

      <div className="detail-payment__bill-list">
        {/*  PRODUCT INFO LIST */}
        <div style={{ paddingRight: orderData.order_violations.length === 0 ? '43px' : '24px' }}>
          <h3>
            {pathName === 'airport-transfer' ? summaryOrderData?.order_airport_package?.title : selectedCar?.name}
          </h3>
          <ul>
            {pathName === 'without-driver' &&
              productInfoList.map((item, ind) => (
                <li key={item.id}>
                  <span>
                    {ind === 0
                      ? orderData.order_detail.without_driver
                        ? item.title
                        : 'Lokasi Penjemputan'
                      : ind === 1
                      ? orderData.order_detail.without_driver
                        ? item.title
                        : 'Lokasi Pengantaran'
                      : item.title}
                  </span>
                  <span>
                    {ind === 0
                      ? deliveryLocation.name
                      : ind === 1
                      ? returnLocation.name
                      : ind === 2
                      ? indonesianDateFormat(orderData.order_detail.start_booking_date)
                      : ind === 3
                      ? addedHourPeriod(orderData.order_detail.start_booking_time)
                      : ind === 4
                      ? endDatePlusOvertime(
                          orderData?.order_detail?.end_booking_date,
                          orderData.order_detail.end_booking_time,
                          orderData.over_time,
                        )
                      : ind === 5
                      ? addedHourPeriod(
                          endTimePlusOvertime(orderData.order_detail.end_booking_time, orderData.over_time),
                        )
                      : ind === 6
                      ? orderData.over_time > 0
                        ? orderData.over_time + ' Jam'
                        : 'Tidak ada overtime'
                      : ''}
                  </span>
                </li>
              ))}

            {pathName === 'with-driver' &&
              productInfoListWithDriver.map((item, ind) => (
                <li key={item.id}>
                  <span>{item.title}</span>
                  <span>
                    {ind === 0
                      ? indonesianDateFormat(orderData.order_detail.start_booking_date)
                      : ind === 1
                      ? endDatePlusOvertime(
                          orderData?.order_detail?.end_booking_date,
                          orderData?.order_detail?.booking_zones[orderData?.order_detail?.booking_zones.length - 1]
                            ?.booking_end_time,
                          0,
                        )
                      : ind === 2
                      ? addedHourPeriod(orderData.order_detail.start_booking_time)
                      : ind === 3
                      ? `${durationRent} Hari`
                      : ''}
                  </span>
                </li>
              ))}

            {pathName === 'airport-transfer' &&
              productInfoListAirportTransfer.map((item, ind) => (
                <li key={item.id}>
                  <span>{item.title}</span>
                  <span>
                    {ind === 0
                      ? deliveryLocation.name
                      : ind === 1
                      ? returnLocation.name
                      : ind === 2
                      ? indonesianDateFormat(orderData.order_detail.start_booking_date)
                      : ind === 3
                      ? addedHourPeriod(orderData.order_detail.start_booking_time)
                      : ''}
                  </span>
                </li>
              ))}
          </ul>
        </div>

        {/* ORDER RENT PRICE LIST */}
        <div style={{ padding: orderData.order_violations.length === 0 ? '0 43px' : '0 24px' }}>
          <h3>Biaya Sewa</h3>
          <ul>
            {pathName === 'without-driver' &&
              orderBillList.map((item, ind) => (
                <li key={item.id}>
                  <span>
                    {ind === 1
                      ? orderData.order_detail.without_driver
                        ? item.title
                        : 'Biaya Penjemputan'
                      : ind === 2
                      ? orderData.order_detail.without_driver
                        ? item.title
                        : 'Biaya Pengantaran'
                      : item.title}
                  </span>
                  <span>
                    {Object.keys(summaryOrderData).length !== 0
                      ? ind === 0
                        ? `IDR ${setCurrency(summaryOrderData.booking_price)?.replace('Rp', '')}`
                        : ind === 1
                        ? deliveryLocation.fee == 0
                          ? 'FREE'
                          : `IDR ${setCurrency(summaryOrderData.rental_delivery_fee)?.replace('Rp', '')}`
                        : returnLocation.fee == 0
                        ? 'FREE'
                        : `IDR ${setCurrency(summaryOrderData.rental_return_fee)?.replace('Rp', '')}`
                      : 'IDR 0'}
                  </span>
                </li>
              ))}

            {pathName === 'with-driver' &&
              summaryOrderData?.order_zone_price?.map((item, i) => (
                <li key={item.day}>
                  <span style={{ width: '190px' }} className="booking-zones">
                    <span>Day - {item.day}:</span>
                    <span>
                      <p>Area:</p>
                      <p>
                        {mappingZonehandler(orderData?.order_detail?.booking_zones[i])}
                        {item.overtime_duration > 0 && ` (OT - ${item.overtime_duration} Jam)`}
                      </p>
                      <p>Jam Selesai:</p>
                      <p>{addedHourPeriod(endTimePlusOvertime(item.booking_end_time, 0))}</p>
                    </span>
                  </span>
                  <span>
                    IDR {setCurrency(item?.total_price + item?.driver_stay_overnight_price)?.replace('Rp', '')}
                  </span>
                </li>
              ))}

            {pathName === 'with-driver' &&
              orderBillListWithDriver.map((item) => (
                <li key={item.id}>
                  <span>{item.title}</span>
                  <span>
                    {Object.keys(summaryOrderData).length !== 0 &&
                      `IDR ${setCurrency(summaryOrderData.booking_price)?.replace('Rp', '')} (All-In)`}
                  </span>
                </li>
              ))}

            {pathName === 'airport-transfer' &&
              orderBillListAiportTransfer.map((item, ind) => (
                <li key={item.id}>
                  <span>{item.title}</span>
                  <span>
                    {Object.keys(summaryOrderData).length !== 0
                      ? ind === 0
                        ? `IDR ${setCurrency(summaryOrderData?.booking_price)?.replace('Rp', '')}`
                        : ind === 1
                        ? summaryOrderData?.rental_delivery_fee == 0
                          ? 'FREE'
                          : `IDR ${setCurrency(summaryOrderData?.rental_delivery_fee)?.replace('Rp', '')}`
                        : summaryOrderData?.rental_return_fee == 0
                        ? 'FREE'
                        : `IDR ${setCurrency(summaryOrderData?.rental_return_fee)?.replace('Rp', '')}`
                      : 'IDR 0'}
                  </span>
                </li>
              ))}
          </ul>
        </div>

        {/* ANOTHER BILL LIST*/}
        <div
          style={{
            padding: orderData.order_violations.length === 0 ? '0 0 0 43px' : '0 24px',
            borderRight: orderData.order_violations.length === 0 ? 'none' : '1px dashed #dbdbdb',
          }}
        >
          <h3>Biaya Lainnya</h3>
          <ul>
            {pathName === 'airport-transfer'
              ? anotherBillListAirportTransfer.map((item, ind) => (
                  <li key={item.id}>
                    <span>
                      {ind === 2 && summaryOrderData?.promotion
                        ? `${item.title} ${summaryOrderData?.promo_name}`
                        : item.title}
                    </span>
                    <span>
                      {Object.keys(summaryOrderData).length !== 0
                        ? ind === 0
                          ? `IDR ${setCurrency(summaryOrderData.service_fee).replace('Rp', '')}`
                          : ind === 1
                          ? `IDR ${setCurrency(summaryOrderData.insurance_fee).replace('Rp', '')}`
                          : ind === 2 && summaryOrderData.promotion
                          ? `-IDR ${setCurrency(summaryOrderData.promo_disc).replace('Rp', '')}`
                          : ind === 3 && summaryOrderData.outside_operational_charge
                          ? `IDR ${setCurrency(summaryOrderData.outside_operational_charge).replace('Rp', '')}`
                          : 'IDR 0'
                        : 'IDR 0'}
                    </span>
                  </li>
                ))
              : pathName !== 'without-driver'
              ? anotherBillListWithDriver.map((item, ind) => (
                  <li key={item.id}>
                    <span>
                      {ind === 2 && summaryOrderData?.promotion
                        ? `${item.title} ${summaryOrderData?.promo_name}`
                        : item.title}
                    </span>
                    <span>
                      {Object.keys(summaryOrderData).length !== 0
                        ? ind === 0
                          ? `IDR ${setCurrency(summaryOrderData.service_fee).replace('Rp', '')}`
                          : ind === 1
                          ? `IDR ${setCurrency(summaryOrderData.insurance_fee).replace('Rp', '')}`
                          : ind === 2 && summaryOrderData.promotion
                          ? `-IDR ${setCurrency(summaryOrderData.promo_disc).replace('Rp', '')}`
                          : ind === 3 && summaryOrderData.exced_max_passenger_charge !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.exced_max_passenger_charge).replace('Rp', '')}`
                          : ind === 4 && summaryOrderData.deposit !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.deposit).replace('Rp', '')}`
                          : // : ind === 5 && summaryOrderData.one_day_order_charge !== 0
                          // ? `IDR ${setCurrency(+summaryOrderData.one_day_order_charge).replace('Rp', '')}`
                          ind === 5 && summaryOrderData.outside_operational_charge !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.outside_operational_charge).replace('Rp', '')}`
                          : ind === 6 && summaryOrderData.order_overtime_total_price !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.order_overtime_total_price).replace('Rp', '')}`
                          : 'IDR 0'
                        : 'IDR 0'}
                    </span>
                  </li>
                ))
              : anotherBillList.map((item, ind) => (
                  <li key={item.id}>
                    <span>
                      {ind === 2 && summaryOrderData?.promotion
                        ? `${item.title} ${summaryOrderData?.promo_name}`
                        : item.title}
                    </span>
                    <span>
                      {Object.keys(summaryOrderData).length !== 0
                        ? ind === 0
                          ? `IDR ${setCurrency(summaryOrderData.service_fee).replace('Rp', '')}`
                          : ind === 1
                          ? `IDR ${setCurrency(summaryOrderData.insurance_fee).replace('Rp', '')}`
                          : ind === 2 && summaryOrderData.promotion
                          ? `-IDR ${setCurrency(summaryOrderData.promo_disc).replace('Rp', '')}`
                          : ind === 3 && summaryOrderData.exced_max_passenger_charge !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.exced_max_passenger_charge).replace('Rp', '')}`
                          : ind === 4 && summaryOrderData.deposit !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.deposit).replace('Rp', '')}`
                          : ind === 5 && summaryOrderData.deposit_e_toll !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.deposit_e_toll).replace('Rp', '')}`
                          : ind === 6 && summaryOrderData.one_day_order_charge !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.one_day_order_charge).replace('Rp', '')}`
                          : ind === 7 && summaryOrderData.outside_operational_charge !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.outside_operational_charge).replace('Rp', '')}`
                          : ind === 8 && summaryOrderData.over_time_price !== 0
                          ? `IDR ${setCurrency(+summaryOrderData.over_time_price).replace('Rp', '')}`
                          : 'IDR 0'
                        : 'IDR 0'}
                    </span>
                  </li>
                ))}
          </ul>
        </div>

        {/* VIOLATIONS */}
        {orderData.order_violations.length !== 0 && (
          <div>
            <h3>Biaya Pelanggaran</h3>
            <ul>
              {orderData.order_violations.map((item) => (
                <li key={item.id}>
                  <span>{item.violation}</span>
                  <span>{`IDR ${setCurrency(+item.cost)?.replace('Rp', '')}`}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* ACTION BUTTON */}
      <div className="detail-payment__action-button">
        <div className="detail-payment__action-button__total-full">
          <p>Total Pembayaran</p>
          <span>
            IDR{' '}
            {summaryOrderData.total_payment !== 0 ? setCurrency(summaryOrderData.total_payment)?.replace('Rp', '') : 0}
          </span>
        </div>

        {orderData.type === 'HALF' && (
          <div className="detail-payment__action-button__total-half">
            <p>Jumlah DP {summaryOrderData.formula_percentage?.value}%</p>
            <span>
              IDR
              {Object.keys(summaryOrderData).length !== 0
                ? setCurrency(summaryOrderData.total_dp)?.replace('Rp', '')
                : 0}
            </span>
          </div>
        )}

        <div className="detail-payment__action-button__buttons">
          <Button
            type="button"
            width="165px"
            height="39px"
            variant="outline"
            size="md"
            className="button"
            onClick={() => {
              prevPage();
              setOrderDataLocalStorage({ ...orderDataLocalStorage, page: 3 });
            }}
          >
            Kembali
          </Button>
          <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
            Lanjutkan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailPembayaran;
