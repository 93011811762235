import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { Button, InputField, RadioButton, SelectFieldVehicle, TableWrapper } from 'components/Global';
import { ReactComponent as VoucherFilledIcon } from 'icons/voucher-filled-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TextAreaInput from 'components/Global/TextArea';
import TextAreaEditor from 'components/Global/TextAreaEditor';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { addDays, format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMatrixVoucher } from 'features/matrix/actions';
import { getAllBusinessPartners } from 'features/business-partner/actions';
import { checkEmptyObject } from 'utils/functionality';
import { getAllVehicles, getVehicleById, getVehiclesByFilter } from 'features/vehicle/action';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import { createNewVoucher, editVoucherById, getVoucherDetail, uploadBannerVoucher } from 'features/vouchers/actions';
import { useAppContext } from 'components/Context/AppContext';

const RADIO_BUTTON = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'fixed',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

const VOUCHER_FOR = [
  {
    id: 1,
    name: 'Reguler',
    value: 'regular',
  },
  {
    id: 2,
    name: 'Bisnis',
    value: 'business',
  },
];

const VOUCHER_TYPES = [
  {
    id: 1,
    name: 'Matrik',
    value: 'matrix',
  },
  {
    id: 2,
    name: 'Publik',
    value: 'public',
  },
];

const VOUCHER_SERVICES = [
  {
    id: 1,
    name: 'With Driver',
    value: 'with_driver',
  },
  {
    id: 2,
    name: 'Without Driver',
    value: 'without_driver',
  },
  {
    id: 3,
    name: 'Airport Transfer',
    value: 'airport_transfer',
  },
];

const VOUCHER_RULES = [
  {
    id: 1,
    name: 'OR',
    value: 'OR',
  },
  {
    id: 2,
    name: 'AND',
    value: 'AND',
  },
];

const INITIAL_STATE = {
  code: '',
  name: '',
  description: '',
  value_type: '',
  value: 0,
  status: 'drafted',
  quota: 0,
  start_date: '',
  end_date: '',
  image: '',
  order_types: [],
  voucher_matrix_ids: [],
  minimum_rent_day: 0,
  voucher_type: '',
  how_to_use: '',
  benefit: '',
  term: '',
  usage_limitation: '',
  type: '',
  condition_matrix: '',
  business_partner_id: 0,
  voucher_business_bundling_lines: [],
};

const VoucherDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast, setShowConfirmation, setShowSpinner } = useAppContext();
  const matrixesData = useSelector((state) => state.matrix.matrix);
  const businessPartners = useSelector((state) => state.businessPartner.data);
  const allVehicle = useSelector((state) => state.vehicle.data);
  const data = useSelector((state) => state.vouchers.selected);
  const [voucherData, setVoucherData] = useState(INITIAL_STATE);
  // const [isVoucherRegular, setIsVoucherRegular] = useState(true);
  // const [isVoucherPublic, setIsVoucherPublic] = useState(true);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [selectedMatrixes, setSelectedMatrixes] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [mappedMatrix, setMappedMatrix] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [valueType, setValueType] = useState('pr1');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const status = searchParams.get('status');
  // const quillRef = useRef();
  const [businessVehicles, setBusinessVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isVoucherPublished = status == 'published';

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    dispatch(getVoucherDetail(id));
  }, [id]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setValueType(() => (data.value_type == 'fixed' ? 'pr1' : 'pr2'));
    setStartDate(() => new Date(data.start_date));
    setEndDate(() => new Date(data.end_date));
    setSelectedOrderType(() => {
      let tempArr = [];
      VOUCHER_SERVICES.map((item) => (data[item.value] ? tempArr.push(item.value) : null));
      return tempArr;
    });
    setVoucherData({
      ...data,
      voucher_type: VOUCHER_FOR.find((item) => item.value == data.voucher_type)?.id,
      type: VOUCHER_TYPES.find((item) => item.value == data.type)?.id,
      condition_matrix:
        data.type === 'matrix' ? VOUCHER_RULES.find((item) => item.value === data.condition_matrix)?.id : '',
      voucher_matrix_ids: data.type === 'matrix' ? data.voucher_matrixes?.map((item) => item.id) : [],
    });

    if (data.type === 'matrix') {
      setSelectedMatrixes(
        data.voucher_matrixes?.map((item) => ({ id: item.id, name: item.name, value: item.name })) || [],
      );
    }

    if (data.voucher_type === 'business') {
      const getSelectedVehicles = async () => {
        const promises = data.voucher_business_bundling_lines.map((item) =>
          dispatch(getVehicleById(item.reference_id)).unwrap(),
        );

        const results = await Promise.allSettled(promises);
        const fulfilledResults = results.filter((result) => result.status === 'fulfilled').map((item) => item.value);

        // loop the vehicle schedule price to set selected car
        let newSelectedCar = [];

        for (let vehicle of fulfilledResults) {
          if (newSelectedCar.length === 0) {
            newSelectedCar.push({ cars: [{ ...vehicle, checked: true }], total: 1 });
          } else {
            const existingVehicle = newSelectedCar.find((item) => item.cars[0].name === vehicle.name);
            if (existingVehicle) {
              const copyNewSelectedCar = [...newSelectedCar];
              copyNewSelectedCar[copyNewSelectedCar.indexOf(existingVehicle)] = {
                cars: [...existingVehicle.cars, { ...vehicle, checked: true }],
                total: existingVehicle.total + 1,
              };
              newSelectedCar = copyNewSelectedCar;
            } else {
              newSelectedCar = [...newSelectedCar, { cars: [{ ...vehicle, checked: true }], total: 1 }];
            }
          }
        }

        setSelectedVehicles(newSelectedCar);
      };

      getSelectedVehicles();
    }

    setIsLoading(false);
  }, [data]);

  const voucherForHandler = (e) => {
    setVoucherData((prev) => ({ ...prev, voucher_type: e.target.id, business_partner_id: 0, value: 0 }));
    setSelectedVehicles([]);
    setBusinessVehicles([]);
  };

  const voucherTypeHandler = (e) => {
    setVoucherData((prev) => ({ ...prev, type: e.target.id, condition_matrix: '' }));
    setSelectedMatrixes([]);
  };

  const orderTypesHandler = (id, status) => {
    const existedType = selectedOrderType.find((item) => item === VOUCHER_SERVICES.find((el) => el.id == id)?.value);

    // if exist will return nothing
    if (existedType && status) return;

    // set tipe sewa if false
    const orderTypeValue = VOUCHER_SERVICES.find((el) => el.id == id)?.value;

    if (status) {
      setSelectedOrderType((prevState) => [...prevState, orderTypeValue]);
    } else {
      const filteredData = selectedOrderType.filter((item) => item !== orderTypeValue);
      setSelectedOrderType(filteredData);
    }
  };

  const matrixHandler = (id, status) => {
    const existedType = selectedMatrixes.find((item) => item === mappedMatrix?.find((el) => el.id == id)?.value);

    // if exist will return nothing
    if (existedType && status) return;

    // set tipe sewa if false
    const orderTypeValue = mappedMatrix?.find((el) => el.id == id);

    if (status) {
      setSelectedMatrixes((prevState) => [...prevState, orderTypeValue]);
    } else {
      const filteredData = selectedMatrixes.filter((item) => item.id !== orderTypeValue.id);
      setSelectedMatrixes(filteredData);
    }
  };

  const selectVehicleshandler = (cars) => {
    let mappingValueVehicles = [];

    if (cars.length) {
      for (let childCar of cars) {
        for (let childCars of childCar.cars) {
          mappingValueVehicles.push({
            reference_id: childCars.id,
            reference_type: 'vehicle',
          });
        }
      }
    }

    setSelectedVehicles(cars);
    setBusinessVehicles(mappingValueVehicles);
  };

  const saveVoucherHandler = async (status) => {
    if (!voucherData.name) {
      return showToast({ type: 'warning', message: 'Harap memasukan nama voucher!' });
    } else if (!voucherData.voucher_type) {
      return showToast({ type: 'warning', message: 'Harap pilih keberlakuan voucher!' });
    } else if (!voucherData.type) {
      return showToast({ type: 'warning', message: 'Harap pilih tipe voucher!' });
    } else if (!selectedOrderType.length) {
      return showToast({ type: 'warning', message: 'Harap pilih layanan voucher!' });
    } else if (!voucherData.code) {
      return showToast({ type: 'warning', message: 'Harap memasukan kode voucher!' });
    } else if (!voucherData.start_date) {
      return showToast({ type: 'warning', message: 'Harap pilih tanggal mulai voucher!' });
    } else if (!voucherData.end_date) {
      return showToast({ type: 'warning', message: 'Harap pilih tanggal selesai voucher!' });
    } else if (!voucherData.description) {
      return showToast({ type: 'warning', message: 'Harap mengisi deskripsi dari voucher!' });
    } else if (!voucherData.image) {
      return showToast({ type: 'warning', message: 'Harap menambahkan gambar banner voucher!' });
    } else if (!voucherData.benefit || voucherData.benefit === '<p><br></p>') {
      return showToast({ type: 'warning', message: 'Harap mengisi benefit dari voucher!' });
    } else if (!voucherData.how_to_use || voucherData.how_to_use === '<p><br></p>') {
      return showToast({ type: 'warning', message: 'Harap mengisi cara penggunaan voucher!' });
    } else if (!voucherData.term || voucherData.term === '<p><br></p>') {
      return showToast({ type: 'warning', message: 'Harap mengisi syarat & ketentuan dari voucher!' });
    } else if (!voucherData.usage_limitation || voucherData.usage_limitation === '<p><br></p>') {
      return showToast({ type: 'warning', message: 'Harap mengisi limitasi voucher!' });
    }

    // if only regular voucher
    if (voucherData.voucher_type == 1) {
      if (!voucherData.value) {
        return showToast({ type: 'warning', message: 'Harap memasukan nilai potongan voucher!' });
      }
    }

    // if business voucher
    if (voucherData.voucher_type == 2) {
      if (!voucherData.business_partner_id) {
        return showToast({ type: 'warning', message: 'Harap pilih partner bisnis!' });
      } else if (!businessVehicles.length) {
        return showToast({ type: 'warning', message: 'Harap pilih mobil!' });
      }
    }

    // if there is matrix
    if (voucherData.type == 1) {
      if (!voucherData.condition_matrix) {
        return showToast({ type: 'warning', message: 'Harap pilih aturan penggunaan voucher!' });
      } else if (!selectedMatrixes.length) {
        return showToast({ type: 'warning', message: 'Harap pilih voucher matrix!' });
      }
    }

    let payload = {
      code: voucherData.code,
      name: voucherData.name,
      description: voucherData.description,
      value_type: valueType == 'pr1' ? 'fixed' : 'percentage',
      value: voucherData.value,
      status: status,
      start_date: voucherData.start_date,
      end_date: voucherData.end_date,
      image: voucherData.image,
      order_types: selectedOrderType,
      minimum_rent_day: voucherData.minimum_rent_day,
      how_to_use: voucherData.how_to_use,
      benefit: voucherData.benefit,
      term: voucherData.term,
      usage_limitation: voucherData.usage_limitation,
      quota: voucherData.quota,
    };

    if (voucherData.type == 1) {
      payload = {
        ...payload,
        voucher_matrix_ids: selectedMatrixes.map((item) => item.id),
        condition_matrix: VOUCHER_RULES.find((item) => item.id == +voucherData.condition_matrix).name,
      };
    }

    // if voucher for business
    if (voucherData.voucher_type == 2) {
      payload = {
        ...payload,
        voucher_type: 'business',
        business_partner_id: +voucherData.business_partner_id,
        voucher_business_bundling_lines: businessVehicles,
      };
    } else {
      payload = {
        ...payload,
        voucher_type: 'regular',
      };
    }

    if (status == 'drafted') {
      if (id) {
        try {
          setShowSpinner(true);
          await dispatch(editVoucherById({ payload, id })).unwrap();
          showToast({
            type: 'success',
            message: 'Sukses update voucher!',
          });
          setShowSpinner(false);
          navigate('/voucher');
        } catch (error) {
          setShowSpinner(false);
          showToast({
            type: 'error',
            message: 'Gagal update voucher!',
          });
        }
      } else {
        try {
          setShowSpinner(true);
          await dispatch(createNewVoucher(payload)).unwrap();
          showToast({
            type: 'success',
            message: 'Sukses membuat voucher baru!',
          });
          setShowSpinner(false);
          navigate('/voucher');
        } catch (error) {
          setShowSpinner(false);
          showToast({
            type: 'error',
            message: 'Gagal membuat voucher baru!',
          });
        }
      }
      return;
    }

    setShowConfirmation({
      message: 'Apakah anda yakin ingin langsung publish voucher?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(createNewVoucher(payload)).unwrap();
          showToast({
            type: 'success',
            message: 'Sukses membuat voucher baru!',
          });
          navigate('/voucher');
        } catch (error) {
          showToast({
            type: 'error',
            message: 'Gagal membuat voucher baru!',
          });
        }
      },
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const intersectionAction = () => {
    // if last page then just return nothing
    if (allVehicle?.pagination.page >= allVehicle?.pagination.last_page) return;

    dispatch(getAllVehicles(allVehicle?.pagination.page + 1));
  };

  const handleUploadImage = async (imageFile) => {
    try {
      const res = await dispatch(uploadBannerVoucher(imageFile)).unwrap();
      setVoucherData((prev) => ({ ...prev, image: res }));
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal melakukan upload banner!' });
    }
  };

  const searchVehicleHandler = (value) => {
    if (!value) {
      dispatch(getAllVehicles());
    } else {
      dispatch(getVehiclesByFilter({ name: value }));
    }
  };

  useEffect(() => {
    dispatch(getAllMatrixVoucher());
    dispatch(getAllBusinessPartners());
  }, []);

  useEffect(() => {
    if (voucherData.voucher_type != 2) return;
    dispatch(getAllVehicles());
  }, [voucherData.voucher_type]);

  useEffect(() => {
    if (checkEmptyObject(matrixesData)) return;
    setMappedMatrix(
      matrixesData?.data?.map((item) => ({ id: item.id, name: item.name, value: item.name.toLowerCase() })),
    );
  }, [matrixesData]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="voucher-detail">
      <button className="voucher-detail__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <TableWrapper
        icon={<VoucherFilledIcon fill="#009EF7" width="25px" height="25px" />}
        title={id ? 'Detail Voucher' : 'Tambah Voucher'}
        CustomFilterComponent={
          isVoucherPublished ? null : (
            <Button width={124} onClick={() => saveVoucherHandler('drafted')} className="btn-draft">
              Simpan Draft
            </Button>
          )
        }
      >
        <div className="voucher-detail__form">
          <div className="form__four-column">
            <InputField
              label="Nama Voucher"
              placeholder="Masukan Nama Voucher"
              disable={isVoucherPublished}
              value={voucherData.name}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, name: e.target.value }))}
            />
            <SelectFieldDropdown
              label="Voucher Berlaku Untuk"
              placeholder="Pilih Voucher Berlaku"
              data={VOUCHER_FOR}
              value={voucherData.voucher_type}
              onChange={voucherForHandler}
              disable={isVoucherPublished}
            />
            <SelectFieldDropdown
              label="Tipe Voucher"
              placeholder="Pilih Tipe Voucher"
              data={VOUCHER_TYPES}
              value={voucherData.type}
              onChange={voucherTypeHandler}
              disable={isVoucherPublished}
            />
            <SelectFieldDropdown
              label="Layanan Voucher"
              placeholder="Pilih Layanan"
              data={VOUCHER_SERVICES}
              checkBox
              value={selectedOrderType}
              onChange={orderTypesHandler}
              className="voucher-services"
              disable={isVoucherPublished}
            />

            <SelectFieldDropdown
              label="Aturan Penggunaan"
              placeholder="Pilih Aturan"
              data={VOUCHER_RULES}
              value={voucherData.condition_matrix}
              // disable={isVoucherPublic || isVoucherPublished}
              disable={!voucherData.type || voucherData.type != 1 ? true : isVoucherPublished ? true : false}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, condition_matrix: e.target.id }))}
            />

            <InputField
              label="Kode Voucher"
              placeholder="Masukan Kode Voucher"
              value={voucherData.code}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, code: e.target.value }))}
              disable={isVoucherPublished}
            />
            <SelectFieldDate
              label="Periode Voucher"
              placeholder="Pilih Tanggal Mulai"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              dateContainerXOffset="left"
              handleDaySelect={(date) => {
                if (date) {
                  // setIsFirstLoad(false);
                  setStartDate(date);
                  setVoucherData((prev) => ({
                    ...prev,
                    start_date: format(date, 'yyyy-MM-dd').toString(),
                  }));
                }
              }}
              selectedDay={startDate}
              value={startDate !== '' ? format(startDate, 'dd-MM-yyyy') : startDate}
              disable={isVoucherPublished}
            />
            <SelectFieldDate
              placeholder="Pilih Tanggal Selesai"
              htmlFor="tanggal-selesai"
              name="tanggal-selesai"
              dateContainerXOffset="left"
              className="end-date"
              handleDaySelect={(date) => {
                if (date) {
                  // setIsFirstLoad(false);
                  setEndDate(date);
                  setVoucherData((prev) => ({
                    ...prev,
                    end_date: format(date, 'yyyy-MM-dd').toString(),
                  }));
                }
              }}
              selectedDay={endDate}
              fromDate={addDays(startDate === '' ? parseISO(startDate) : startDate, 1)}
              value={endDate !== '' ? format(endDate, 'dd-MM-yyyy') : endDate}
              disable={isVoucherPublished}
            />
            {!voucherData.voucher_type || voucherData.voucher_type == 1 ? (
              <>
                <div className="input-group__voucher-discount">
                  <h4>Potongan Voucher</h4>
                  <div className="radio-group">
                    <RadioButton
                      name="price"
                      data={RADIO_BUTTON}
                      state={valueType}
                      disable={isVoucherPublished}
                      setState={(e) => {
                        if (isVoucherPublished) return;
                        setValueType(e);
                      }}
                    />
                  </div>
                  <InputField
                    placeholder={valueType === 'pr1' ? 'Rp 0' : valueType === 'pr2' ? '0%' : 'Rp 0'}
                    onWheel={numberInputOnWheelPreventChange}
                    type="number"
                    min={0}
                    value={voucherData.value || ''}
                    disable={isVoucherPublished}
                    onChange={(e) => setVoucherData((prev) => ({ ...prev, value: +e.target.value }))}
                  />
                </div>
                <SelectFieldDropdown
                  label="Voucher Matrix"
                  placeholder="Pilih Voucher Matriks"
                  data={mappedMatrix}
                  // disable={isVoucherPublic}
                  disable={!voucherData.type || voucherData.type != 1 ? true : isVoucherPublished ? true : false}
                  value={selectedMatrixes}
                  onChange={matrixHandler}
                  checkBox
                />
              </>
            ) : null}

            {voucherData.voucher_type && voucherData.voucher_type != 1 ? (
              <SelectFieldDropdown
                label="Voucher Matrix"
                placeholder="Pilih Voucher Matriks"
                data={mappedMatrix}
                // disable={isVoucherPublic}
                disable={!voucherData.type || voucherData.type != 1 ? true : isVoucherPublished ? true : false}
                value={selectedMatrixes}
                onChange={matrixHandler}
                checkBox
              />
            ) : null}

            <InputField
              label="Minimal Rental (Hari)"
              placeholder="Masukan Jumlah Minimal Rental"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              value={
                isVoucherPublished
                  ? !voucherData?.minimum_rent_day
                    ? 0
                    : voucherData.minimum_rent_day
                  : voucherData.minimum_rent_day || ''
              }
              onChange={(e) => setVoucherData((prev) => ({ ...prev, minimum_rent_day: +e.target.value }))}
              disable={isVoucherPublished}
              min={0}
            />

            <InputField
              label="Kuota Voucher"
              placeholder="Masukan Jumlah Kuota"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              value={voucherData.quota || ''}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, quota: +e.target.value }))}
              disable={isVoucherPublished}
              min={0}
            />

            {!voucherData.voucher_type || voucherData.voucher_type == 1 ? null : (
              <SelectFieldDropdown
                label="Partner Bisnis"
                placeholder="Pilih Partner Bisnis"
                data={businessPartners?.data}
                value={voucherData.business_partner_id}
                onChange={(e) => setVoucherData((prev) => ({ ...prev, business_partner_id: e.target.id }))}
                disable={isVoucherPublished}
              />
            )}

            {!voucherData.voucher_type || voucherData.voucher_type == 1 ? null : (
              <SelectFieldVehicle
                className="form__four-column__vehicles"
                label="Pilih Mobil"
                placeholder="Pilih Mobil"
                htmlFor="jenis-mobil"
                data={allVehicle}
                selectedCar={selectedVehicles}
                onSelectCar={selectVehicleshandler}
                intersectionAction={intersectionAction}
                disable={isVoucherPublished}
                onSearchAction={searchVehicleHandler}
              />
            )}
          </div>

          <div className="form__two-column">
            <TextAreaInput
              label="Deskripsi"
              placeholder="Masukan Deskripsi Voucher"
              htmlFor="deskripsi"
              className="text-area-input"
              value={voucherData.description}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, description: e.target.value }))}
              disable={isVoucherPublished}
            />
            <div className="input-group__banner-input">
              <h4>Banner Voucher</h4>
              <UploadImageCustomOrder
                htmlFor="banner-voucher"
                uploadAction={handleUploadImage}
                imageFile={voucherData.image}
                disable={isVoucherPublished}
              />
            </div>
          </div>

          <div className="input-group__text-editor">
            <h4>Benefit</h4>
            <TextAreaEditor
              id="benefit"
              value={voucherData.benefit}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, benefit: e }))}
              disable={isVoucherPublished}
            />
          </div>

          <div className="input-group__text-editor">
            <h4>Cara Penggunaan Voucher</h4>
            <TextAreaEditor
              id="how-to"
              value={voucherData.how_to_use}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, how_to_use: e }))}
              disable={isVoucherPublished}
            />
          </div>

          <div className="input-group__text-editor">
            <h4>Syarat dan Ketentuan</h4>
            <TextAreaEditor
              id="terms"
              value={voucherData.term}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, term: e }))}
              disable={isVoucherPublished}
            />
          </div>

          <div className="input-group__text-editor">
            <h4>Usage Limitation</h4>
            <TextAreaEditor
              id="usage"
              value={voucherData.usage_limitation}
              onChange={(e) => setVoucherData((prev) => ({ ...prev, usage_limitation: e }))}
              disable={isVoucherPublished}
            />
          </div>
        </div>

        <div className="voucher-detail__buttons">
          <Button width={162} onClick={() => navigate(-1)} variant="outline">
            Kembali
          </Button>
          {isVoucherPublished ? null : (
            <Button width={162} onClick={() => saveVoucherHandler('published')}>
              Publish
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default VoucherDetail;
