import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ReactComponent as Clock } from '../../../icons/clock-icon.svg';
import ClickOutside from '../ClickOutside';

const SelectFieldTimeWithDriver = ({
  label,
  name,
  htmlFor,
  value, // state
  className,
  style,
  placeholder,
  onChange,
  disable,
  startTime, // optional
  // choosenDate,
  icon = true,
  ...rest
}) => {
  // TODAY'S TIME
  // const currentHour = new Date().getHours();
  // const today = new Date().getDate();
  // const thisMonth = new Date().getMonth();
  // const thisYear = new Date().getFullYear();

  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // STATE HOUR & MINUTE
  const [hour, setHour] = useState('07');
  const [minute, setMinute] = useState('00');

  // SET SELECTED HOUR AND MINUTE
  useEffect(() => {
    if (!value) return;
    setHour(value?.split(':')[0]);
    setMinute(value?.split(':')[1]);
  }, [value]);

  // START BOOKING HOUR
  const startHour = +startTime?.split(':')[0];
  // START BOOKING MINUTE
  const startMinute = +startTime?.split(':')[1] === 0 ? [0] : [0, 30];

  // LENGTH OF HOUR OPTIONS
  const hourLength = startTime ? 18 : 24;

  // DIFFERENCE DAYS STATE
  // const [differenceDays, setDifferenceDays] = useState(null);

  // SET THE TIME
  const setTimeBooking = (hour, minute) => {
    onChange(hour, minute);
    setIsShowDropdown(false);
  };

  // SET DIFFERENCE DAYS WHEN CHOOSEN DATE CHANGING
  // useEffect(() => {
  //   const difference =
  //     choosenDate && choosenDate.getMonth() === thisMonth && choosenDate.getFullYear() === thisYear
  //       ? choosenDate.getDate() - today
  //       : 1;
  //   setDifferenceDays(difference);
  // }, [choosenDate]);

  return (
    <div className={clsx('select-field-time-with-driver', className)} style={{ width: '100%' }}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-time-with-driver__label">
          {label}
        </label>
      )}
      <div
        className={
          disable ? 'select-field-time-with-driver__input-field disable' : 'select-field-time-with-driver__input-field'
        }
        style={{ width: '100%' }}
      >
        {icon && <Clock className="select-field-time-with-driver__input-field__calendar" />}
        <input
          id={htmlFor}
          type="text"
          placeholder={placeholder}
          name={name}
          style={style}
          className="select-field-time-with-driver__input-field__input"
          value={value}
          disabled={disable}
          onFocus={() => setIsShowDropdown(true)}
          readOnly
          {...rest}
        />
      </div>
      {isShowDropdown && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <div className="select-field-time-with-driver__dropdown" style={{ width: '246px' }}>
            <div className="select-field-time-with-driver__dropdown__container">
              <div className="select-field-time-with-driver__dropdown__container__jam">
                <h4>Jam</h4>
                <ul>
                  {/* if theres hourStart, use the hourStart if not start from 7 o'clock */}
                  {Array.from(
                    { length: hourLength },
                    (_, i) => i + (startTime ? (startHour === 23 ? startHour : startHour + 1) : 0),
                  ).map((item, idx) =>
                    item <= 23 ? (
                      <li
                        key={idx}
                        className={
                          // differenceDays === 0 && item < currentHour
                          //   ? 'list-hour disable-hour'
                          //   :
                          item == hour ? 'list-hour active' : 'list-hour'
                        }
                        onClick={() => {
                          item < 10 ? setHour(`0${item}`) : setHour(item);
                        }}
                      >
                        {item}
                      </li>
                    ) : null,
                  )}
                </ul>
              </div>
              <div className="select-field-time-with-driver__dropdown__container__menit">
                <h4>Menit</h4>
                <ul>
                  {/* if there is startTime and difference between selected hour and startHour is 12, show startMinute*/}
                  {(startTime && hour - startHour === 12
                    ? startMinute
                    : !startTime && hour === 23
                    ? [0]
                    : hour === startHour
                    ? [30]
                    : // else show all minutes
                      [0, 30]
                  ).map((item, idx) => (
                    <li
                      key={idx}
                      className={item == minute ? 'list-minute active' : 'list-minute'}
                      onClick={() => {
                        item == 0 ? setMinute(`0${item}`) : setMinute(item);
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="select-field-time-with-driver__dropdown__container__selesai">
              <h3 onClick={() => setTimeBooking(hour, minute)}>Selesai</h3>
            </div>
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default SelectFieldTimeWithDriver;
