import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { Button, PaginationTable, TableWrapper } from 'components/Global';
import { ReactComponent as VoucherFilledIcon } from 'icons/voucher-filled-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { deleteVoucherById, getAllVouchers } from 'features/vouchers/actions';
import { format, parseISO } from 'date-fns';
import StatusButton from 'components/Global/Table/StatusButton';
import { setCurrency } from 'utils/functionality';
import ActionButtons from 'components/Global/Table/ActionButtons';
import { resetSelectedVoucher } from 'features/vouchers/voucherSlice';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Voucher', value: 'name' },
  { header: 'Periode Mulai', value: 'start_date' },
  { header: 'Periode Selesai', value: 'end_date' },
  { header: 'Voucher Matrix', value: 'type' },
  { header: 'Harga Potongan', value: 'value' },
  { header: 'Status', value: 'status' },
];

const VoucherComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: voucherData, isLoading } = useSelector((state) => state.vouchers);
  const { setShowSpinner, showToast, setShowConfirmation } = useAppContext();
  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  // LOCAL STATE
  const [currentPage, setCurrentPage] = useState(1);

  const handleEdit = (e) => {
    const voucherId = e.target.parentElement.parentElement.dataset['key'];
    const selectedVoucher = voucherData?.data.find((item) => item.id === +voucherId);

    dispatch(resetSelectedVoucher());
    navigate(`/voucher/detail?id=${voucherId}${selectedVoucher ? `&status=${selectedVoucher.status}` : ''}`);
  };

  const handleDelete = (e) => {
    const voucherId = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus voucher ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteVoucherById(voucherId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Voucher' });
          dispatch(getAllVouchers(1));
          setCurrentPage(1);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: error });
        }
      },
    });
  };

  const handleDetail = (e) => {
    const voucherId = e.target.parentElement.parentElement.dataset['key'];
    const selectedVoucher = voucherData?.data.find((item) => item.id === +voucherId);

    dispatch(resetSelectedVoucher());
    navigate(`/voucher/detail?id=${voucherId}${selectedVoucher ? `&status=${selectedVoucher.status}` : ''}`);
  };

  const handleAdd = () => {
    dispatch(resetSelectedVoucher());
    navigate(`/voucher/add-item`);
  };

  useEffect(() => {
    dispatch(getAllVouchers(currentPage));
  }, [currentPage]);

  const data = voucherData?.data?.map((item) => ({
    ...item,
    start_date: format(parseISO(item.start_date), 'dd/MM/yyyy'),
    end_date: format(parseISO(item.end_date), 'dd/MM/yyyy'),
  }));

  const rows = 10 - data?.length;

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="voucher">
      <div className="button-wrapper">
        <Button onClick={handleAdd} width="192px" height="39px" className="button-add">
          <PlusIcon />
          <span className="button-label">Tambah Voucher</span>
        </Button>
      </div>

      <TableWrapper icon={<VoucherFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Voucher">
        <div className="table">
          <table className="table-wrapper">
            <thead className="table-head">
              <tr>
                {column.map((item, i) => (
                  <th key={i} style={{ width: item.header === 'Jenis Mobil' ? '240px' : '' }}>
                    {item.header}
                  </th>
                ))}
                <th className="action-col">Action</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data?.length > 0 &&
                data?.map((item, no) => (
                  <tr key={no}>
                    {column.map((col, idx) => {
                      if (col.value === 'no') {
                        return (
                          <td
                            key={idx}
                            data-type="no"
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            {pageNumber(currentPage, no + 1)}
                          </td>
                        );
                      } else if (col.value === 'status') {
                        return (
                          <td
                            key={idx}
                            data-type={'status'}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            {<StatusButton status={item[col.value]} />}
                          </td>
                        );
                      } else if (col.value === 'value') {
                        return (
                          <td
                            key={idx}
                            data-type={col.value}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            {item.value_type == 'fixed' ? setCurrency(item[col.value]) : `${item[col.value]}%`}
                          </td>
                        );
                      } else if (col.value === 'type') {
                        return (
                          <td
                            key={idx}
                            data-type={col.value}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            <span style={{ background: '#F2F2F2', padding: '8px 21px', borderRadius: '100px' }}>
                              {item[col.value]}
                            </span>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={idx}
                            data-type={col.value}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            {item[col.value]}
                          </td>
                        );
                      }
                    })}
                    <td className="btn-action" data-type="actionButtons" data-key={!item.id ? item.code : item.id}>
                      {item.status == 'drafted' ? (
                        <ActionButtons edit={handleEdit} />
                      ) : (
                        <ActionButtons detail={handleDetail} />
                      )}

                      <ActionButtons del={handleDelete} />
                    </td>
                  </tr>
                ))}
              {rows > 0 &&
                [...Array(rows)].map((_, idx) => (
                  <tr key={idx}>
                    {column.map((_, idx) => (
                      <td key={idx}></td>
                    ))}
                    <td className="btn-action"></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* <Table
          column={column}
          actionBtn
          onEdit={handleEdit}
          onDel={handleDelete}
          data={data}
          currentPage={currentPage}
        /> */}
        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={Object.keys(voucherData).length === 0 ? 0 : voucherData.pagination.total}
        />
      </TableWrapper>
    </div>
  );
};

export default VoucherComponent;
