import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { useSelector } from 'react-redux';
// import { getRefferalReportLatest } from 'features/refferal-report/actions';
import { checkEmptyObject } from 'utils/functionality';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import TablePoint from './TablePoint';

const COLUMNS = [
  { header: 'No.', value: 'no' },
  { header: 'Nama', value: 'user_name' },
  { header: 'Email', value: 'email' },
  { header: 'Tanggal dan Waktu', value: 'date' },
  { header: 'Kode Refferal', value: 'referral' },
  { header: 'Point', value: 'point' },
];

const TransaksiPointTerbaru = () => {
  // const dispatch = useDispatch();

  const { data, isLoading } = useSelector((state) => state.refferalReportLatest);
  const [newData, setNewData] = useState([]);

  // const filterYearHandler = () => {
  //   const thisDay = format(new Date(), 'yyyy-MM-dd');
  //   const oneYearAgo = format(subYears(new Date(), 1), 'yyyy-MM-dd');

  //   dispatch(getRefferalReportLatest({ startDate: oneYearAgo, endDate: thisDay }));
  // };

  useEffect(() => {
    if (!data || checkEmptyObject(data)) return;

    const mappingData = data?.map((item) => ({
      ...item,
      date: format(new Date(item.date), 'dd MMMM yyyy HH:mm:ss', { locale: id }),
    }));

    setNewData(mappingData);
  }, [data]);

  return (
    <CardDashboardItem
      title="Transaksi Point Terbaru"
      asChild
      separator
      // filter
      // filterAlign="end"
      // filterAction={getRefferalReportLatest}
      // filterYearAction={filterYearHandler}
    >
      <TablePoint data={newData} isLoading={isLoading} columns={COLUMNS} />
    </CardDashboardItem>
  );
};

export default TransaksiPointTerbaru;
