import { InputField } from 'components/Global';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import React from 'react';

const EmployeeDetail = ({ businessPartnerData, data, setData }) => {
  return (
    <div className="employee-detail__form">
      <div className="form__two-column">
        <SelectFieldDropdown
          label="Pilih Partner Bisnis"
          data={businessPartnerData}
          placeholder="Pilih Partner Bisnis"
          onChange={(e) => setData((prev) => ({ ...prev, business_partner_id: +e.target.id }))}
          value={data.business_partner_id}
        />
        <InputField
          label="PIN"
          placeholder="Masukan PIN"
          type="number"
          value={data.pin}
          min={0}
          onChange={(e) => {
            if (e.target.value.length > 6) return;
            setData((prev) => ({ ...prev, pin: e.target.value }));
          }}
        />
      </div>
    </div>
  );
};

export default EmployeeDetail;
