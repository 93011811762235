import { useAppContext } from 'components/Context/AppContext';
import dayjs from 'dayjs';

const Header = () => {
  const { monthIndex, setMonthIndex } = useAppContext();

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }

  return (
    <div className="driver-task__header">
      <div className="driver-task__header__current-month-wrapper">
        <h2 className="driver-task__header__current-month">
          {dayjs(new Date(dayjs().year(), monthIndex)).format('MMMM YYYY')}
        </h2>
      </div>
      <div className="driver-task__header__btn-group">
        <button className="driver-task__header__btn" onClick={handlePrevMonth}>
          <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66802 0L7 1.2925L2.67341 5.5L7 9.7075L5.66802 11L0 5.5L5.66802 0Z" fill="white" />
          </svg>
        </button>
        <button className="driver-task__header__btn" onClick={handleNextMonth}>
          <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33198 0L0 1.2925L4.32659 5.5L0 9.7075L1.33198 11L7 5.5L1.33198 0Z" fill="white" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Header;
