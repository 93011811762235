import { createSlice } from '@reduxjs/toolkit';
import {
  getAllBusinessPartners,
  getAllPartnerEmployees,
  getBusinessPartnerDetail,
  getPartnerEmployeeDetail,
} from './actions';

const initialState = {
  data: {},
  employees: {},
  selected: {},
  selectedEmployee: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: {},
};

export const businessPartner = createSlice({
  name: 'businessPartner',
  initialState,
  reducers: {
    resetSelectedBusiness: (state) => {
      (state.selected = {}), (state.selectedEmployee = {});
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBusinessPartners.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllBusinessPartners.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllBusinessPartners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getBusinessPartnerDetail.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getBusinessPartnerDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getBusinessPartnerDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selected = action.payload;
      })
      .addCase(getAllPartnerEmployees.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.employees = {};
      })
      .addCase(getAllPartnerEmployees.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllPartnerEmployees.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.employees = action.payload;
      })
      .addCase(getPartnerEmployeeDetail.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.selectedEmployee = {};
      })
      .addCase(getPartnerEmployeeDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getPartnerEmployeeDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedEmployee = action.payload;
      });
  },
});

export const { resetSelectedBusiness } = businessPartner.actions;
export default businessPartner.reducer;
