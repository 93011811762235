import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllMatrix = createAsyncThunk('matrix/getAllMatrix', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/matrixs?limit=10&page=1&type=overtime`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllMatrixVoucher = createAsyncThunk('matrix/getAllMatrixVoucher', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/matrixs?limit=10&page=1&type=voucher`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
