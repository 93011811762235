import { Button, InputField } from 'components/Global';
import React from 'react';

const DriverTaskDetail = ({ data, driverTask, getFileName, imagePreviewHandler }) => {
  return (
    <div className="detail-order__payment-detail">
      {(data.order_status === 'COMPLETED' || data.order_status === 'FINISHED') && (
        <div className="detail-order__return-detail">
          <div className="detail-order__return-detail-title">
            <h1>Foto Ambil Dari Garasi</h1>
          </div>
          <div className="detail-order__return-detail-body">
            {driverTask.histories?.length > 0 ? (
              driverTask.histories
                ?.find((item) => item.status == 'TAKE_FROM_GARAGE')
                ?.photos?.map((item, idx) => (
                  <div className="detail-order__payment-detail-body__preview-image" key={idx}>
                    <InputField
                      label={idx === 0 ? 'Foto Pengambilan' : ''}
                      disabled
                      value={getFileName(item)}
                      className="preview-image-input"
                    />
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() => imagePreviewHandler(item)}
                    >
                      Lihat
                    </Button>
                  </div>
                ))
            ) : (
              <InputField label="Foto Pengambilan" disabled value="-" className="preview-image-input" />
            )}

            <InputField
              label="Keterangan"
              disabled
              value={driverTask.histories?.find((item) => item.status == 'TAKE_FROM_GARAGE')?.note || '-'}
              className="preview-image-input"
            />
          </div>
        </div>
      )}

      {data.order_status === 'FINISHED' && (
        <div className="detail-order__garage-detail">
          <div className="detail-order__garage-detail-title">
            <h1>Foto Kembalikan ke Garasi</h1>
          </div>
          <div className="detail-order__garage-detail-body">
            {driverTask.histories?.length > 0 ? (
              driverTask.histories
                ?.find((item) => item.status == 'RETURN_TO_GARAGE')
                ?.photos?.map((item, idx) => (
                  <div className="detail-order__payment-detail-body__preview-image" key={idx}>
                    <InputField
                      label={idx === 0 ? 'Foto Pengembalian' : ''}
                      disabled
                      value={getFileName(item)}
                      className="preview-image-input"
                    />
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() => imagePreviewHandler(item)}
                    >
                      Lihat
                    </Button>
                  </div>
                ))
            ) : (
              <InputField label={'Foto Pengembalian'} disabled value="-" className="preview-image-input" />
            )}

            <InputField
              label="Keterangan"
              disabled
              value={driverTask.histories?.find((item) => item.status == 'RETURN_TO_GARAGE')?.note || '-'}
              className="preview-image-input"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DriverTaskDetail;
