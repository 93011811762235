export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        file: reader.result,
      });
    reader.onerror = (error) => reject(error);
  });
};

export const convertBase64toImage = async (base64File, fileName) => {
  try {
    const res = await fetch(base64File);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

// export const setCurrency = (price) => {
//   if (typeof price != 'number') return price;

//   return new Intl.NumberFormat('id-ID', {
//     style: 'currency',
//     currency: 'IDR',
//     maximumSignificantDigits: 6,
//   }).format(price);
// };

export const setCurrency = (price) => {
  if (typeof price !== 'number') return price;

  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0, // No decimal places
    maximumFractionDigits: 0, // No decimal places
  }).format(price);
};

export const phoneFormatter = (number) => {
  function validatePhoneWithZero(phoneNumber) {
    const re = /^0\d{9,12}/g;
    return re.test(phoneNumber);
  }

  function validate62(phoneNumber) {
    const re = /^62\d{9,12}/g;
    return re.test(phoneNumber);
  }

  if (validatePhoneWithZero(number)) {
    const newNumber = number.replace(/^0/g, '62');
    return newNumber;
  }

  if (validate62(number)) {
    return number;
  }
};

export const removePhoneCode = (code, number) => {
  const codeNumber = code.slice(1, code.length);

  function validatePhoneWithCode(number) {
    const form = `^${codeNumber}\\d{9,12}`;
    const re = new RegExp(form, 'g');
    return re.test(number);
  }

  if (validatePhoneWithCode(number)) {
    const replace = `^${codeNumber}`;
    const re = new RegExp(replace, 'g');
    const newNumber = number.replace(re, '');
    return newNumber;
  } else {
    return number.replace(/^0/g, '');
  }
};

// force phone number to have 62 at the start
export const phoneFormatterWithCode = (code, number) => {
  const codeNumber = code.slice(1, code.length);

  function validatePhoneWith0(phoneNumber) {
    const re = /^0\d{9,12}/g;
    return re.test(phoneNumber);
  }

  if (validatePhoneWith0(number)) {
    const newNumber = number.replace(/^0/g, codeNumber);
    return newNumber;
  } else {
    return number;
  }
};

// force phone number to have 0 at the start
// code dalam bentuk string dan masih ada tanda +, contoh "+62"
export const phoneFormatter0 = (code, number) => {
  const codeNumber = code.slice(1, code.length);

  function validatePhoneWithCode(phoneNumber) {
    const form = `^${codeNumber}\\d{9,12}`;
    const re = new RegExp(form, 'g');
    return re.test(phoneNumber);
  }

  if (validatePhoneWithCode(number)) {
    const replace = `^${codeNumber}`;
    const re = new RegExp(replace, 'g');
    const newNumber = number.replace(re, '0');
    return newNumber;
  } else {
    return number;
  }
};

export const phoneWithCode = (code = '', number) => {
  const codeNumber = code.slice(1, code.length);

  function validatePhoneWithCode(number) {
    const form = `^${codeNumber}\\d{9,12}`;
    const re = new RegExp(form, 'g');
    return re.test(number);
  }

  if (/^0\d{9,12}/g.test(number)) {
    return phoneFormatterWithCode(code, number);
  } else if (validatePhoneWithCode(number)) {
    const replace = `^${codeNumber}`;
    const re = new RegExp(replace, 'g');
    const newNumber = number.replace(re, '');
    return codeNumber.concat(newNumber);
  } else return codeNumber.concat(number);
};

export const toPascalCase = (str) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');

export const mappingLongTimeZones = (timeZones) => {
  let newTimeZones = [];

  for (let zone of timeZones) {
    zone.longTimezones.forEach((item) => {
      newTimeZones.push(item);
    });
  }

  return newTimeZones;
};

export const getGMTOffsetString = () => {
  const date = new Date();
  const timezoneOffset = date.getTimezoneOffset();

  const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
  const offsetSign = timezoneOffset > 0 ? '-' : '+';

  return `GMT${offsetSign}${offsetHours}:${offsetMinutes}`;
};

export const checkEmptyObject = (targetObject) => {
  return Object.keys(targetObject).length === 0 && targetObject.constructor === Object;
};

export const checkPermission = (offCanvasMenu, currentMenu, actionType) => {
  const getPermission = offCanvasMenu?.find((item) => item?.name?.toLowerCase() === currentMenu?.toLowerCase());
  if (!getPermission) return;
  return Object.prototype.hasOwnProperty.call(getPermission, 'action')
    ? Boolean(getPermission?.action[actionType])
    : false;
};
