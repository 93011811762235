import React, { useEffect } from 'react';
import { useState } from 'react';
import { TableWrapper, Table, PaginationTable } from 'components/Global';
import { ReactComponent as BusinessPartnerIcon } from 'icons/business-partner-filled-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { deleteBusinessPartnerEmployee, getAllPartnerEmployees } from 'features/business-partner/actions';
import { resetSelectedBusiness } from 'features/business-partner/slice';

// COLUMN
const column = [
  { header: 'No.', value: 'no' },
  { header: 'Partner Bisnis', value: 'name' },
  { header: 'PIN', value: 'pin' },
];

const EmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setShowSpinner, showToast, setShowConfirmation } = useAppContext();
  const { employees: partnerEmployees, isLoading } = useSelector((state) => state.businessPartner);
  const { data: businessPartners } = useSelector((state) => state.businessPartner);

  const [currentPage, setCurrentPage] = useState(1);

  const getBusinessName = (businessId) => {
    const selected = businessPartners?.data?.find((item) => item.id == businessId);
    return selected ? selected.name : '-';
  };

  // FUNCTION TO GO TO DETAIL PAGE
  const handleDetail = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];

    dispatch(resetSelectedBusiness());
    navigate(`/business-partner/detail?tab=Karyawan&id=${id}`);
  };

  // FUNCTION TO DELETE
  const handleDelete = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus data ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteBusinessPartnerEmployee(id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menghapus Data!' });
          setCurrentPage(1);
          dispatch(getAllPartnerEmployees(1));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Gagal Menghapus Data!' });
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllPartnerEmployees(currentPage));
  }, [currentPage]);

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  const data = partnerEmployees?.data?.map((item) => ({
    ...item,
    name: getBusinessName(item.business_partner_id),
  }));

  return (
    <>
      {!partnerEmployees || !partnerEmployees.data || partnerEmployees.data.length === 0 ? (
        <div className="message">Data Kosong</div>
      ) : (
        <TableWrapper icon={<BusinessPartnerIcon fill="#009EF7" width="25px" height="25px" />} title="Partner Bisnis">
          <Table
            column={column}
            actionBtn
            onDetail={handleDetail}
            onDel={handleDelete}
            data={data}
            currentPage={currentPage}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={partnerEmployees?.paging?.total_data}
          />
        </TableWrapper>
      )}
    </>
  );
};

export default EmployeeList;
