import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import TaskInformation from './TaskInformation';
import { ReactComponent as Calendar } from '../../../../icons/calendar-icon.svg';
import { ReactComponent as Duration } from '../../../../icons/duration-icon.svg';
import { ReactComponent as Clock } from '../../../../icons/clock-icon.svg';
import { ReactComponent as DateIcon } from '../../../../icons/calendar-icon.svg';
import { ReactComponent as ChevronDown } from '../../../../icons/chevron-down.svg';
import { ReactComponent as MappinBlue } from '../../../../icons/mappin-icon-blue.svg';
import { findCountryByCode } from '../TaskDetailWithoutDriver';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskDetail } from 'features/driver-task/actions';
import { indonesianDateFormat } from 'utils/helpers';
import { addDays, differenceInDays, format, parseISO } from 'date-fns';
import { setCurrency } from 'utils/functionality';
import { getAllZone } from 'features/zone/actions';
import { fetchCustomerInfo } from 'features/user/actions';
import { getCustomerData } from 'features/user/slice';

const TaskDetailWithDriver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { taskId } = useParams();
  const { selectedTask: data } = useSelector((state) => state.driverTask);
  const { data: zoneData } = useSelector((state) => state.zone);
  const customer = useSelector(getCustomerData);

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });

  // const [highestZone, setHighestZone] = useState('');

  // const getHighestZone = (arrZoneId) => {
  //   arrZoneId.sort((a, b) => +a.charAt(a.length - 1) - +b.charAt(b.length - 1))[2];
  // };

  useEffect(() => {
    // if (Object.keys(rentalLocationData).length === 0) return;

    // const baliLocationId = rentalLocationData.find((item) => item.name === 'BALI')?.id;
    dispatch(getAllZone());
  }, []);

  useEffect(() => {
    if (data) {
      setSelectedCountry(findCountryByCode(data?.order?.phone_country_code));
    }
  }, [data]);

  useEffect(() => {
    if (!taskId) return;
    dispatch(getTaskDetail(taskId));
  }, [taskId]);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    dispatch(fetchCustomerInfo(data?.order?.customer_id));
  }, [data]);

  // const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [accordionNumberOpen, setAccordionNumberOpen] = useState(0);

  const getZone = (zoneId) => {
    return zoneData.find((item) => item.id === zoneId).name;
  };

  const backHandler = () => {
    navigate(-1);
  };

  const handleOpen = (item) => {
    // setIsFirstLoad(false);
    // if (item === accordionNumberOpen || item > 1) {
    if (item === accordionNumberOpen) {
      setAccordionNumberOpen(0);
    } else {
      setAccordionNumberOpen(item);
    }
  };

  const formRef = useRef(null);

  const getItem = (bookingZoneDate, historyStatus) => {
    // console.log(
    //   'wkwk',
    //   data.histories.find(
    //     (item) => format(parseISO(item.date_time), 'yyyy-MM-dd') === bookingZoneDate && item.status === historyStatus,
    //   ),
    // );

    // return data.histories.map((item) => '2024-03-01' === bookingZoneDate && item.status === historyStatus);

    // return data.histories.map(
    //   (item) => format(parseISO(item.date_time), 'yyyy-MM-dd') === bookingZoneDate && item.status === historyStatus,
    // );

    const filteredData = data.histories.find((item) => {
      // const formattedDate = format(parseISO(item.date_time), 'yyyy-MM-dd');
      const formattedDate = format(parseISO(item.date_time), 'yyyy-MM-dd');
      return formattedDate === bookingZoneDate && item.status === historyStatus;
    });

    return filteredData;
  };
  return (
    <div className="task-detail">
      <div className="task-detail__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Driver 1">
        <div className="task-detail__renter-detail">
          <div className="task-detail__renter-detail-title">
            <h1>Detail Penyewa</h1>
          </div>
          <div className="task-detail__renter-detail-body">
            <InputField label="Nama Lengkap" disabled value={data?.order?.user_name || '-'} />
            <InputField label="No Order" disabled value={data?.order?.order_key || '-'} />

            <div className="task-detail__renter-detail-body__phone-number">
              <InputField
                label="No Handphone"
                disabled
                value={data?.order?.is_admin_creation ? data?.order?.phone_country_code : customer.phone_code || '-'}
                icon={
                  <img
                    src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                    width={26}
                    height={16}
                    alt={selectedCountry?.name + ' nation flag'}
                  />
                }
              />
              <InputField value={data?.order?.phone_number || '-'} disabled />
            </div>

            <InputField label="Plat Nomor" disabled value={data?.order?.order_detail.vehicle.license_number || '-'} />
            <InputField
              label="Jumlah Penumpang"
              disabled
              value={`${data?.order?.order_detail.passenger_number} orang` || '-'}
            />
            <InputField label="Mobil" disabled value={data?.order?.order_detail.vehicle.name || '-'} />
          </div>
        </div>

        <div className="task-detail__rent-detail">
          <div className="task-detail__rent-detail-title">
            <h1>Detail Sewa</h1>
          </div>
          <div className="task-detail__rent-detail-body">
            <InputField
              label="Tanggal Mulai"
              disabled
              value={
                data?.order?.order_detail?.start_booking_date
                  ? indonesianDateFormat(data?.order?.order_detail?.start_booking_date)
                  : '-'
              }
              icon={<Calendar />}
              iconPosition="start"
            />
            <InputField
              label="Durasi Sewa"
              disabled
              value={
                `${differenceInDays(
                  addDays(new Date(data?.order?.order_detail?.end_booking_date), 1),
                  new Date(data?.order?.order_detail?.start_booking_date),
                )} Hari` || '-'
              }
              icon={<Duration />}
              iconPosition="start"
            />

            {data.order?.order_detail.booking_zones.map((item, idx) => (
              <React.Fragment key={idx}>
                <div className="task-detail__rent-detail-body__rent-date">
                  <InputField
                    label={`Day ${idx + 1}`}
                    disabled
                    value={item.date ? indonesianDateFormat(item.date) : '-'}
                  />
                  <InputField
                    label="Tambahan Overtime (Jika Ada)"
                    disabled
                    value={
                      item.overtime_duration > 0
                        ? `${item.overtime_duration} Jam (IDR${setCurrency(item.overtime_price).replace('Rp', '')})`
                        : 'Tidak Ada Overtime'
                    }
                  />
                </div>

                <div className="task-detail__rent-detail-body__rent-date">
                  <InputField
                    label="Jam Mulai"
                    disabled
                    value={item.booking_start_time || '-'}
                    icon={<Clock />}
                    iconPosition="start"
                  />
                  <InputField
                    label="Jam Selesai"
                    disabled
                    value={item.booking_end_time || '-'}
                    icon={<Clock />}
                    iconPosition="start"
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </TableWrapper>

      <div className="task-detail__booking-zone__wrapper">
        <div className="task-detail__renter-detail-title">
          <h1>Zona Penyewaan</h1>
        </div>
        <ul className="task-detail__booking-zone__form">
          {data.order?.order_detail.booking_zones.map((item, idx) => (
            <li key={idx} className="task-detail__list-booking-zone">
              <div className="header" onClick={() => handleOpen(idx + 1)}>
                <div className="header__date">
                  <div>
                    <DateIcon />
                    <span>Day-{idx + 1}</span>
                  </div>
                  <div>
                    <span />
                    <p>{item.date ? indonesianDateFormat(item.date) : '-'}</p>
                  </div>
                  {/* <div className="selected-zone zone-zero">Zone 0</div> */}
                </div>
                <ChevronDown className={`chevron ${accordionNumberOpen === idx + 1 ? 'up' : 'down'}`} />
              </div>

              <div className="form" style={{ height: accordionNumberOpen === idx + 1 ? 'auto' : 0 }}>
                <div ref={formRef} className="form__wrapper">
                  <div className="form__input-data">
                    <InputField
                      label="Pick Up"
                      disabled
                      value={getZone(item.pickup_zone_id)}
                      icon={<MappinBlue />}
                      iconPosition="start"
                    />
                    <InputField
                      label="Drop Off"
                      disabled
                      value={getZone(item.drop_off_zone_id)}
                      icon={<MappinBlue />}
                      iconPosition="start"
                    />
                    <InputField
                      label="Tambah Area Penyewaan"
                      disabled
                      value={getZone(item.driving_zone_id)}
                      icon={<MappinBlue />}
                      iconPosition="start"
                    />
                  </div>

                  <>
                    <div className="task-detail__line"></div>
                    <TaskInformation
                      title="Ambil Dari Garasi"
                      style={{ marginTop: '10px' }}
                      item={getItem(item.date, 'TAKE_FROM_GARAGE')}
                    />
                  </>

                  <>
                    <div className="task-detail__line"></div>
                    <TaskInformation title="Parkir ke Garasi" style={{ marginTop: '10px' }} />
                  </>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="task-detail__booking-zone__back-btn-wrapper">
          <Button variant="outline" width={252} onClick={() => navigate(-1)}>
            Kembali
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailWithDriver;
