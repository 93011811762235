import { useAppContext } from 'components/Context/AppContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TaskModal = () => {
  const navigate = useNavigate();
  const { setShowTaskModal, selectedTask, daySelected } = useAppContext();
  // console.log('kiw', selectedTask);

  const handleDetail = ({ withoutDriver, id }) => {
    if (withoutDriver) {
      navigate(`/driver-task/without-driver-detail/${id}`);
    } else {
      navigate(`/driver-task/with-driver-detail/${id}`);
    }
    setShowTaskModal(false);
  };

  return (
    <div className="driver-task__modal-wrapper">
      <div className="driver-task__modal-content">
        <div className="driver-task__modal-header">
          <h3>{daySelected.format('dddd, DD MMMM YYYY')}</h3>
          <button className="driver-task__modal-header__close-btn" onClick={() => setShowTaskModal(false)}>
            <span>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.80764 0.808617C0.865697 0.750413 0.934667 0.704234 1.0106 0.672726C1.08653 0.641218 1.16793 0.625 1.25014 0.625C1.33235 0.625 1.41375 0.641218 1.48968 0.672726C1.56561 0.704234 1.63458 0.750413 1.69264 0.808617L5.00014 4.11737L8.30764 0.808617C8.36575 0.750507 8.43474 0.704412 8.51066 0.672963C8.58659 0.641514 8.66796 0.625327 8.75014 0.625327C8.83232 0.625327 8.9137 0.641514 8.98962 0.672963C9.06554 0.704412 9.13453 0.750507 9.19264 0.808617C9.25075 0.866727 9.29685 0.935713 9.32829 1.01164C9.35974 1.08756 9.37593 1.16894 9.37593 1.25112C9.37593 1.3333 9.35974 1.41467 9.32829 1.4906C9.29685 1.56652 9.25075 1.63551 9.19264 1.69362L5.88389 5.00112L9.19264 8.30862C9.25075 8.36673 9.29685 8.43571 9.32829 8.51164C9.35974 8.58756 9.37593 8.66894 9.37593 8.75112C9.37593 8.8333 9.35974 8.91467 9.32829 8.9906C9.29685 9.06652 9.25075 9.13551 9.19264 9.19362C9.13453 9.25173 9.06554 9.29782 8.98962 9.32927C8.9137 9.36072 8.83232 9.37691 8.75014 9.37691C8.66796 9.37691 8.58659 9.36072 8.51066 9.32927C8.43474 9.29782 8.36575 9.25173 8.30764 9.19362L5.00014 5.88487L1.69264 9.19362C1.63453 9.25173 1.56554 9.29782 1.48962 9.32927C1.4137 9.36072 1.33232 9.37691 1.25014 9.37691C1.16796 9.37691 1.08659 9.36072 1.01066 9.32927C0.934737 9.29782 0.86575 9.25173 0.80764 9.19362C0.74953 9.13551 0.703435 9.06652 0.671986 8.9906C0.640537 8.91467 0.624351 8.8333 0.624351 8.75112C0.624351 8.66894 0.640537 8.58756 0.671986 8.51164C0.703435 8.43571 0.74953 8.36673 0.80764 8.30862L4.11639 5.00112L0.80764 1.69362C0.749436 1.63556 0.703258 1.56659 0.671749 1.49066C0.640241 1.41473 0.624023 1.33333 0.624023 1.25112C0.624023 1.16891 0.640241 1.08751 0.671749 1.01157C0.703258 0.935643 0.749436 0.866674 0.80764 0.808617Z"
                  fill="#A8A8A8"
                />
              </svg>
            </span>
          </button>
        </div>

        <div className="driver-task__modal-body">
          {selectedTask.length > 0 &&
            selectedTask.map((task) => (
              <div key={task.id} className="driver-task__items">
                <div className="driver-task__item-group">
                  <h4>{task.driver_name || '-'}</h4>
                  <span>&#x2022;</span>
                  <p>{task.task_key}</p>
                </div>

                <p>
                  {task.start_date} - {task.end_date}
                </p>
                <div className="driver-task__bottom-group">
                  <p>
                    {task.order.user_name} | {task.order.order_detail.vehicle.name || '-'}
                  </p>
                  <button
                    className="driver-task__bottom-group__detail-btn"
                    onClick={() => handleDetail({ withoutDriver: task.order.order_detail.without_driver, id: task.id })}
                  >
                    View Detail
                    <span>
                      <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33198 0L0 1.2925L4.32659 5.5L0 9.7075L1.33198 11L7 5.5L1.33198 0Z" fill="#0084FF" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            ))}

          {/* <div className="driver-task__items">
            <div className="driver-task__item-group">
              <h4>Bessie Cooper</h4>
              <span>&#x2022;</span>
              <p>GR02547896</p>
            </div>

            <p>11 Jan 2022 - 12 Jan 2022</p>
            <div className="driver-task__bottom-group">
              <p>Kevin Sanjaya | Suzuki Ertiga</p>
              <button className="driver-task__bottom-group__detail-btn">
                View Detail
                <span>
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33198 0L0 1.2925L4.32659 5.5L0 9.7075L1.33198 11L7 5.5L1.33198 0Z" fill="#0084FF" />
                  </svg>
                </span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
