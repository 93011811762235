import { createSlice } from '@reduxjs/toolkit';
import { fetchSummaryOrder, fetchSummaryAirportTransferOrder, fetchSummaryCustomerOrder } from './actions';

const initialState = {
  data: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const summaryOrder = createSlice({
  name: 'summaryOrder',
  initialState,
  reducers: {
    // reducers goes here

    setSummaryData: (state, action) => {
      state.data = action.payload;
    },

    resetSummaryData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummaryOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSummaryOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchSummaryOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSummaryAirportTransferOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSummaryAirportTransferOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchSummaryAirportTransferOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSummaryCustomerOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSummaryCustomerOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchSummaryCustomerOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const { setSummaryData, resetSummaryData } = summaryOrder.actions;
export default summaryOrder.reducer;
