import { createSlice } from '@reduxjs/toolkit';
import { getDriverTask, getTaskDetail } from './actions';

const initialState = {
  data: {},
  selectedTask: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const driverTask = createSlice({
  name: 'driverTask',
  initialState,
  reducers: {
    resetSelectedDriverTask: (state) => {
      state.selectedTask = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverTask.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getDriverTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDriverTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.data = action.payload;
      })
      .addCase(getTaskDetail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selectedTask = {};
      })
      .addCase(getTaskDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getTaskDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selectedTask = action.payload;
      });
  },
});

export const { resetSelectedDriverTask } = driverTask.actions;
export default driverTask.reducer;
