import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { Button, TableWrapper } from 'components/Global';
import { ReactComponent as BusinessPartnerIcon } from 'icons/business-partner-filled-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BusinessDetail from './business-detail';
import EmployeeDetail from './employee-detail';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRentalLocation } from 'features/rental-location/actions';
import {
  addBusinessPartnerEmployee,
  addNewBusinessPartner,
  editBusinessPartnerById,
  editBusinessPartnerEmployee,
  getAllBusinessPartners,
  getBusinessPartnerDetail,
  getPartnerEmployeeDetail,
  uploadBusinessPartnerLogo,
} from 'features/business-partner/actions';
import { checkEmptyObject } from 'utils/functionality';
import { useAppContext } from 'components/Context/AppContext';

const INITIAL_BUSINESS_STATE = {
  name: '',
  email: '',
  phone: '',
  description: '',
  address: '',
  location_id: 0,
  logo: '',
};

const BusinessPartnerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [businessData, setBusinessData] = useState(INITIAL_BUSINESS_STATE);
  const [mappedBusinessList, setMappedBusinessList] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    business_partner_id: 0,
    pin: '',
  });
  const [image, setImage] = useState('');
  const [searchParams] = useSearchParams();
  const tabActive = searchParams.get('tab');
  const id = searchParams.get('id');
  const {
    data: businessPartners,
    selected: selectedBusiness,
    selectedEmployee,
    isLoading,
  } = useSelector((state) => state.businessPartner);
  const { data: rentalLocation } = useSelector((state) => state.rentalLocation);
  const { showToast, setShowSpinner } = useAppContext();

  const isEdit = id ? true : false;

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllBusinessPartners());
  }, []);

  useEffect(() => {
    if (!id) return;
    if (tabActive == 'Perusahaan') {
      dispatch(getBusinessPartnerDetail(id));
    } else {
      dispatch(getPartnerEmployeeDetail(id));
    }
  }, [id, tabActive]);

  useEffect(() => {
    if (!businessPartners || checkEmptyObject(businessPartners) || !businessPartners.data) return;
    const copyData = [...businessPartners.data];
    setMappedBusinessList(copyData.map((item) => ({ id: item.id, title: item.name, value: item.id })));
  }, [businessPartners]);

  useEffect(() => {
    if (!selectedEmployee || checkEmptyObject(selectedEmployee)) return;
    setEmployeeData(selectedEmployee);
  }, [selectedEmployee]);

  useEffect(() => {
    if (!selectedBusiness || checkEmptyObject(selectedBusiness)) return;
    setBusinessData(selectedBusiness);
    setImage(selectedBusiness?.logo);
  }, [selectedBusiness]);

  const saveBusinessHandler = async () => {
    if (!businessData.name) {
      showToast({
        type: 'warning',
        message: 'Harap mengisi data Nama bisnis!',
      });
      return;
    }
    if (!businessData.email) {
      showToast({
        type: 'warning',
        message: 'Harap mengisi data Email bisnis!',
      });
      return;
    }
    if (!businessData.phone) {
      showToast({
        type: 'warning',
        message: 'Harap mengisi No. Handphone!',
      });
      return;
    }
    if (!businessData.address) {
      showToast({
        type: 'warning',
        message: 'Harap mengisi data Alamat!',
      });
      return;
    }
    if (!businessData.location_id) {
      showToast({
        type: 'warning',
        message: 'Harap memilih Lokasi bisnis!',
      });
      return;
    }
    if (!businessData.logo) {
      showToast({
        type: 'warning',
        message: 'Harap memilih Logo bisnis!',
      });
      return;
    }
    if (!businessData.description) {
      showToast({
        type: 'warning',
        message: 'Harap mengisi Deskripsi bisnis!',
      });
      return;
    }

    try {
      if (isEdit) {
        await dispatch(editBusinessPartnerById({ payload: businessData, id: id })).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil merubah data partner bisnis!',
        });
      } else {
        await dispatch(addNewBusinessPartner(businessData)).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil menambah data partner bisnis!',
        });
      }
      navigate('/business-partner?tab-active=Perusahaan');
    } catch (error) {
      if (isEdit) {
        showToast({
          type: 'error',
          message: `Gagal merubah data perusahaan! - ${error.message}`,
        });
        return;
      }
      showToast({
        type: 'error',
        message: `Gagal menambah data perusahaan! - ${error.message}`,
      });
    }
  };

  const saveEmployeeHandler = async () => {
    if (!employeeData.business_partner_id) {
      showToast({
        type: 'warning',
        message: 'Harap memilih partner bisnis!',
      });
      return;
    }

    if (!employeeData.pin) {
      showToast({
        type: 'warning',
        message: 'Harap mengisi PIN!',
      });
      return;
    }

    try {
      if (isEdit) {
        await dispatch(editBusinessPartnerEmployee({ payload: employeeData, id: id })).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil merubah data karyawan!',
        });
      } else {
        await dispatch(addBusinessPartnerEmployee(employeeData)).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil menambah data karyawan!',
        });
      }
      navigate('/business-partner?tab-active=Karyawan');
    } catch (error) {
      if (isEdit) {
        showToast({
          type: 'error',
          message: `Gagal merubah data karyawan! - ${error.message}`,
        });
        return;
      }
      showToast({
        type: 'error',
        message: `Gagal menambah data karyawan! - ${error.message}`,
      });
    }
  };

  const uploadImageHandler = async (image) => {
    try {
      const res = await dispatch(uploadBusinessPartnerLogo(image)).unwrap();
      setImage(image.name);
      setBusinessData((prev) => ({ ...prev, logo: res.file }));
    } catch (error) {
      showToast({
        type: 'error',
        message: `Gagal Upload Logo Bisnis Partner - ${error.message}`,
      });
    }
  };

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="business-detai">
      <button className="business-detail__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <TableWrapper
        icon={<BusinessPartnerIcon fill="#009EF7" width="25px" height="25px" />}
        title={`Tambah ${tabActive}`}
      >
        {tabActive == 'Perusahaan' ? (
          <BusinessDetail
            locationData={rentalLocation}
            data={businessData}
            setData={setBusinessData}
            uploadAction={uploadImageHandler}
            image={image}
          />
        ) : (
          <EmployeeDetail businessPartnerData={mappedBusinessList} data={employeeData} setData={setEmployeeData} />
        )}

        <div className="business-detail__buttons">
          <Button width={162} onClick={() => navigate(-1)} variant="outline">
            Kembali
          </Button>
          <Button width={162} onClick={tabActive == 'Perusahaan' ? saveBusinessHandler : saveEmployeeHandler}>
            Simpan
          </Button>
        </div>
      </TableWrapper>
    </div>
  );
};

export default BusinessPartnerDetail;
