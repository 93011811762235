import React from 'react';
import Day from './Day';

const Month = ({ month }) => {
  return (
    <div className="driver-task__month-wrapper">
      <div className="driver-task__days">
        {DAYS.map((day, idx) => (
          <p key={idx}>{day}</p>
        ))}
      </div>
      <div className="driver-task__month">
        {month.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <Day day={day} key={idx} rowIdx={i} />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Month;

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
