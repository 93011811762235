import React from 'react';
import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as VehicleIcon } from '../../../icons/car-blue-bg.svg';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Button } from 'components/Global';
import { assignCourier } from 'features/couriers/actions';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import { fetchOrderById } from 'features/orders/actions';

const AssignDriverModal = () => {
  const dispatch = useDispatch();
  const { setShowAssignDriver, showToast, setShowSpinner } = useAppContext();

  const couriersData = useSelector((state) => state.couriers.data);
  const orderData = useSelector(getDetailOrder);

  const [selectedDriver, setSelectedDriver] = useState(null);

  const handleSubmit = async () => {
    // check if valid
    if (!selectedDriver) {
      return showToast({ type: 'error', message: 'Pilih Driver' });
    }

    const payload = {
      transaction_key: orderData.transaction_key,
      user_id: selectedDriver,
    };

    try {
      setShowSpinner(true);
      await dispatch(assignCourier(payload)).unwrap();

      showToast({ type: 'success', message: 'Berhasil Assign Driver' });
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Assign Driver' });
    } finally {
      setShowAssignDriver(false);
      setShowSpinner(false);
      dispatch(fetchOrderById(payload.transaction_key));
    }
  };

  return (
    <Modals
      setState={setShowAssignDriver}
      title="Pilih Driver"
      icon={<VehicleIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="assign-driver">
        <SelectFieldDropdown
          label="Assign to"
          htmlFor="assign-driver"
          placeholder="Pilih Driver"
          data={couriersData?.data || []}
          value={selectedDriver}
          onChange={(e) => setSelectedDriver(e.target.id)}
        />

        <div className="assign-driver__action">
          <Button variant="outline" width={208} size="sm" onClick={() => setShowAssignDriver(false)}>
            Kembali
          </Button>
          <Button variant="primary" width={208} size="sm" onClick={handleSubmit}>
            Simpan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AssignDriverModal;
