import React from 'react';
import { Quill } from 'react-quill';
import { ReactComponent as CustomUndo } from 'icons/undo-editor-icon.svg';
import { ReactComponent as CustomRedo } from 'icons/redo-editor-icon.svg';
import { ReactComponent as CustomBold } from 'icons/bold-editor-icon.svg';
import { ReactComponent as CustomItalic } from 'icons/italic-editor-icon.svg';
import { ReactComponent as CustomUnderline } from 'icons/underline-editor-icon.svg';
import { ReactComponent as CustomStrike } from 'icons/strike-editor-icon.svg';
import { ReactComponent as CustomClean } from 'icons/clean-editor-icon.svg';
import { ReactComponent as CustomLink } from 'icons/link-editor-icon.svg';
import { ReactComponent as CustomCode } from 'icons/code-editor-icon.svg';
import { ReactComponent as CustomBlokquote } from 'icons/quote-editor-icon.svg';
import { ReactComponent as CustomImage } from 'icons/image-editor-icon.svg';
import { ReactComponent as CustomBullet } from 'icons/list-bullet-editor-icon.svg';
import { ReactComponent as CustomOrdered } from 'icons/list-number-editor-icon.svg';

// functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

function boldChange() {
  this.quill.format('bold', !this.quill.getFormat().bold);
}
Quill.import('ui/icons').bold = CustomBold;

function italicChange() {
  this.quill.format('italic', !this.quill.getFormat().italic);
}
Quill.import('ui/icons').italic = CustomItalic;

function underlineChange() {
  this.quill.format('underline', !this.quill.getFormat().underline);
}
Quill.import('ui/icons').underline = CustomUnderline;

function strikeChange() {
  this.quill.format('strike', !this.quill.getFormat().strike);
}
Quill.import('ui/icons').strike = CustomStrike;

function cleanChange() {
  this.quill.format('clean', !this.quill.getFormat().clean);
}
Quill.import('ui/icons').clean = CustomClean;

function codeChange() {
  this.quill.format('code-block', !this.quill.getFormat()['code-block']);
}
Quill.import('ui/icons')['code-block'] = CustomCode;

function quoteChange() {
  this.quill.format('blokquote', !this.quill.getFormat().blockquote);
}
Quill.import('ui/icons').blockquote = CustomBlokquote;

Quill.import('ui/icons').link = CustomLink;
Quill.import('ui/icons').image = CustomImage;
Quill.import('ui/icons').list = CustomBullet;
Quill.import('ui/icons').ordered = CustomOrdered;

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = (toolbarId) => ({
  toolbar: {
    container: '#' + toolbarId,
    handlers: {
      undo: undoChange,
      redo: redoChange,
      bold: boldChange,
      italic: italicChange,
      underline: underlineChange,
      strike: strikeChange,
      clear: cleanChange,
      'code-block': codeChange,
      blokquote: quoteChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
});

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

// Quill Toolbar component
export const QuillToolbar = ({ id, disable }) => (
  <div id={id} style={{ pointerEvents: disable ? 'none' : 'default' }}>
    {/* <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-video" />
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span> */}

    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>

    <span className="ql-formats">
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>

    <span className="ql-formats">
      <select className="ql-align" />
    </span>

    <span className="ql-formats">
      <select className="ql-background" />
    </span>

    <span className="ql-formats">
      <button className="ql-bold">
        <CustomBold />
      </button>
      <button className="ql-italic">
        <CustomItalic />
      </button>
      <button className="ql-underline">
        <CustomUnderline />
      </button>
      <button className="ql-strike">
        <CustomStrike />
      </button>
      <button className="ql-clean">
        <CustomClean />
      </button>
    </span>

    <span className="ql-formats">
      <button className="ql-list" value="bullet">
        <CustomBullet />
      </button>
      <button className="ql-list" value="ordered">
        <CustomOrdered />
      </button>
    </span>

    <span className="ql-formats">
      <button className="ql-link">
        <CustomLink />
      </button>
      <button className="ql-image">
        <CustomImage />
      </button>
      <button className="ql-code-block">
        <CustomCode />
      </button>
      <button className="ql-blockquote">
        <CustomBlokquote />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
