import { useAppContext } from 'components/Context/AppContext';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const { monthIndex, setShowTaskModal } = useAppContext();
  const { data } = useSelector((state) => state.driverTask);
  const [taskList, setTaskList] = useState([]);

  function filterDataByMonth(dataArray, targetMonth) {
    return dataArray.filter((item) => {
      const month = new Date(item.start_date).getMonth();
      return month === targetMonth;
    });
  }

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    const filteredData = filterDataByMonth(data.data, monthIndex);

    const groupedData = groupDataByDate(filteredData);

    setTaskList(groupedData);
  }, [data, monthIndex]);

  function groupDataByDate(dataArray) {
    const groupedData = {};

    dataArray.forEach((item) => {
      const dateKey = item.start_date; // Using toDateString to consider only the date part

      if (!groupedData[dateKey]) {
        groupedData[dateKey] = [];
      }

      groupedData[dateKey].push(item);
    });

    return groupedData;
  }

  const handleDetail = ({ withoutDriver, id }) => {
    if (withoutDriver) {
      navigate(`/driver-task/without-driver-detail/${id}`);
    } else {
      navigate(`/driver-task/with-driver-detail/${id}`);
    }
    setShowTaskModal(false);
  };

  return (
    <div className="driver-task__sidebar">
      <div className="driver-task__search-wrapper">
        <input
          className="search-input"
          type="text"
          placeholder="Search"
          // value={value}
          // onChange={(e) => onChange(e.target.value)}
        />
        <img src="/assets/icon/search-icon.svg" className="search-icon" alt="search icon" />
      </div>

      <div className="driver-task__sidebar-task-list-wrapper">
        {Object.entries(taskList).map(([date, data]) => (
          <ul className="driver-task__sidebar-task-list" key={date}>
            <h4 className="driver-task__sidebar-task-date">{dayjs(date).format('dddd, DD MMMM YYYY')}</h4>
            {data.map((item, idx) => (
              <div className="driver-task__sidebar-item" key={idx}>
                <div className="driver-task__sidebar-item-group">
                  <h4>{item.driver_name}</h4>
                  <span>&#x2022;</span>
                  <p>{item.task_key}</p>
                </div>

                <p>
                  {item.start_date} - {item.end_date}
                </p>
                <p>
                  {item.order.user_name} | {item.order.order_detail.vehicle.name}
                </p>
                <button
                  className="driver-task__bottom-group__detail-btn driver-task__sidebar-detail-btn"
                  onClick={() => handleDetail({ withoutDriver: item.order.order_detail.without_driver, id: item.id })}
                >
                  View Detail
                  <span>
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.33198 0L0 1.2925L4.32659 5.5L0 9.7075L1.33198 11L7 5.5L1.33198 0Z" fill="#0084FF" />
                    </svg>
                  </span>
                </button>
              </div>
            ))}
          </ul>
        ))}

        {/* <ul className="driver-task__sidebar-task-list">
          <h4 className="driver-task__sidebar-task-date">Thursday, 11 January 2022</h4>
          <div className="driver-task__sidebar-item">
            <div className="driver-task__sidebar-item-group">
              <h4>Bessie Cooper</h4>
              <span>&#x2022;</span>
              <p>GR02547896</p>
            </div>

            <p>11 Jan 2022 - 12 Jan 2022</p>
            <p>Kevin Sanjaya | Suzuki Ertiga</p>
            <button className="driver-task__bottom-group__detail-btn driver-task__sidebar-detail-btn">
              View Detail
              <span>
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.33198 0L0 1.2925L4.32659 5.5L0 9.7075L1.33198 11L7 5.5L1.33198 0Z" fill="#0084FF" />
                </svg>
              </span>
            </button>
          </div>
        </ul> */}
      </div>
    </div>
  );
};

export default Sidebar;
