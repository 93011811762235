import { createSlice } from '@reduxjs/toolkit';
import { getAllCouriers, getAllDrivers, getCourierDetail } from './actions';

const initialState = {
  data: {},
  selectedCourier: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const couriers = createSlice({
  name: 'couriers',
  initialState,
  reducers: {
    // reducers goes here
    resetSelectedCourier: (state) => {
      state.selectedCourier = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCouriers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllCouriers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllCouriers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAllDrivers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllDrivers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllDrivers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getCourierDetail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selectedCourier = {};
      })
      .addCase(getCourierDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getCourierDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedCourier = action.payload;
      });
  },
});

export const { resetSelectedCourier } = couriers.actions;
export default couriers.reducer;
