import { createSlice } from '@reduxjs/toolkit';
import { getAirportVehicleById, getAllVehicleAirportPackage, getVehicleByIdAirportPackage } from './action';

const initialState = {
  data: {},
  selected: {},
  selectedPackageVechile: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const vehicleAirportPackage = createSlice({
  name: 'vehicleAirportPackage',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicleAirportPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllVehicleAirportPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllVehicleAirportPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getVehicleByIdAirportPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selectedPackageVechile = {};
      })
      .addCase(getVehicleByIdAirportPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVehicleByIdAirportPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedPackageVechile = action.payload;
      })
      .addCase(getAirportVehicleById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getAirportVehicleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAirportVehicleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      });
  },
});

export default vehicleAirportPackage.reducer;
