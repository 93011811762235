import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  getRefferalReportGraphic,
  getRefferalReportLatest,
  getRefferalReportRanking,
  getRefferalReportTotalPoint,
} from 'features/refferal-report/actions';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import GrafikPoint from './grafikPoint';
import RankingPoint from './rankingPoint';
import TransaksiPointTerbaru from './transaksi-point-terbaru';
import { ReactComponent as DashboardRefferalIcon } from 'icons/dashboard-refferal-icon-white.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';

const DashboardRefferal = () => {
  const dispatch = useDispatch();

  const { data: TotalPointData } = useSelector((state) => state.refferalReportTotalPoint);

  const filterYearHandler = () => {
    const thisDay = format(new Date(), 'yyyy-MM-dd');
    const firstDayInYear = `${new Date().getFullYear()}-01-01`;

    dispatch(getRefferalReportTotalPoint({ startDate: firstDayInYear, endDate: thisDay }));
  };

  useEffect(() => {
    const thisDay = format(new Date(), 'yyyy-MM-dd');
    const firstDayInYear = `${new Date().getFullYear()}-01-01`;

    dispatch(getRefferalReportTotalPoint({ startDate: firstDayInYear, endDate: thisDay }));
    dispatch(getRefferalReportGraphic({ startDate: firstDayInYear, endDate: thisDay }));
    dispatch(getRefferalReportLatest({ startDate: firstDayInYear, endDate: thisDay }));
    dispatch(getRefferalReportRanking({ startDate: firstDayInYear, endDate: thisDay }));
  }, []);

  return (
    <div className="dashboard-refferal">
      <div className="dashboard-refferal__header">
        <div className="circle">
          <DashboardRefferalIcon />
        </div>
        <h1>Dashboar Refferal</h1>
      </div>

      <CardDashboardItem
        title="Total Point Generated"
        filter
        filterAlign="end"
        filterAction={getRefferalReportTotalPoint}
        filterYearAction={filterYearHandler}
        asChild
      >
        <div className="dashboard-refferal__total-point">
          <PointsIcon className="icon" />
          <p>{TotalPointData?.total_point || 0}</p>
        </div>
      </CardDashboardItem>

      <GrafikPoint />

      <RankingPoint />

      <TransaksiPointTerbaru />
    </div>
  );
};

export default DashboardRefferal;
